import { gql } from "@apollo/client";

export const SIGN_IN = gql`
 mutation SignIn($email: ValidString!, $password: ValidString!) {
  signin(input: { email: $email, password: $password }) {
   user {
    name
    email
    isAdmin
    isVa
    isBroker
    isAgent
    approved
    #status
    defaultCityId
    defaultPropertyView
    subscriptions {
     isTrial
     currentEndDatetime
     currentStartDatetime
     active
     status
    }
   }
   authenticationToken
	 refreshToken
   message
   errors
  }
 }
`;

export const SIGN_OUT = gql`
	mutation signout{
		signout(input: {}) {
			clientMutationId
			message
		}
	}
`;

export const SIGN_OUT_AS = gql`
	mutation signoutAs{
		signoutAs(input: {}) {
			clientMutationId
			message
		}
	}
`;

export const SIGN_IN_AS = gql`
 mutation SignInAs($userId: Int!) {
  signinAs(input: { userId: $userId }) {
   user {
    name
    email
    isAdmin
    isVa
    isBroker
    isAgent
    approved
    #status
    defaultCityId
    defaultPropertyView
    subscriptions {
     isTrial
     currentEndDatetime
     currentStartDatetime
     active
     status
    }
   }
   authenticationToken
   message
   errors
  }
 }
`;

export const SIGN_UP = gql`
 mutation signup(
  $email: ValidString!
  $password: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $licenseNo: ValidString
  $planId: Int!
  $cityIds: [Int!]!
 ) {
  signup(
   input: {
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    licenseNo: $licenseNo
    planId: $planId
    cityIds: $cityIds
   }
  ) {
   user {
    name
   }
   message
   errors
  }
 }
`;

export const AGENT_SIGNUP = gql`
mutation agentSingup(
  $email: ValidString!
  $password: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $licenseNo: ValidString
  $phoneNo: ValidString
  $brokerId: Int!
  $cityIds: [Int!]!
 ) {
  agentSingup(
   input: {
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    licenseNo: $licenseNo
    phoneNo: $phoneNo
    brokerId: $brokerId
		cityIds: $cityIds
   }
  ) {
   user {
    name
   }
   message
   errors
  }
 }`;

export const UPDATE_PASSWORD = gql`
 mutation updatePassword($oldPassword: ValidString!, $newPassword: ValidString!) {
  updatePassword(
   input: { oldPassword: $oldPassword, newPassword: $newPassword }
  ) {
   message
   errors
  }
 }
`;

export const CONTACTINQUIRY_CREATE = gql`
 mutation contactinquiryCreate(
  $email: ValidString
  $phone: ValidString
  $inquiryReason: ValidString
  $message: ValidString
 ) {
  contactinquiryCreate(
   input: {
    email: $email
    phone: $phone
    inquiryReason: $inquiryReason
    message: $message
   }
  ) {
   contactinquiry {
    id
   }
   message
   errors
  }
 }
`;

export const INQUIRIES_MULTI_DELETE = gql`
 mutation contactinquiryMultiDelete($ids: [Int!]!) {
  contactinquiryMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const NOTIFICATION_CREATE = gql`
 mutation notificationCreate($title: ValidString!, $description: ValidString!) {
  notificationCreate(input: { title: $title, description: $description }) {
   notification {
    id
   }
   message
   errors
  }
 }
`;

export const UPDATE_NOTIFICATION = gql`
 mutation notificationUpdate(
  $title: ValidString!
  $description: ValidString!
  $id: Int!
	$notificationType: NotificationType!
 ) {
  notificationUpdate(
   input: { title: $title, description: $description, id: $id, notificationType: $notificationType}
  ) {
   errors
   message
  }
 }
`;

export const SEND_FEEDBACK = gql`
 mutation sendFeedback($title: ValidString!, $description: ValidString!, $feedbackFiles: [File!]) {
  sendFeedback(input: { title: $title, description: $description, feedbackFiles: $feedbackFiles }) {
   errors
   message
  }
 }
`;

export const FORGET_PASSWORD = gql`
 mutation forgotPassword($email: ValidString!) {
  forgotPassword(input: { email: $email }) {
   errors
   message
  }
 }
`;

export const USER_CREATE = gql`
 mutation userCreate(
  $email: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $password: ValidString!
  $phoneNo: ValidString
  $licenseNo: ValidString
  $isVa: Boolean
  $isAdmin: Boolean
  $isBroker: Boolean
  $approved: Boolean
  $brokerageName: ValidString
	$otpRequiredForLogin: Boolean
 ) {
  userCreate(
   input: {
    email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
    phoneNo: $phoneNo
    licenseNo: $licenseNo
    isVa: $isVa
    isAdmin: $isAdmin
    isBroker: $isBroker
    approved: $approved
    brokerageName: $brokerageName
		otpRequiredForLogin: $otpRequiredForLogin
   }
  ) {
   user {
    id
   }
   errors
   message
  }
 }
`;

export const USER_UPDATE = gql`
 mutation userUpdate(
  $id: Int!
  $email: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $password: ValidString!
  $phoneNo: ValidString
  $licenseNo: ValidString
  $isVa: Boolean
  $isAdmin: Boolean
  $isBroker: Boolean
  $approved: Boolean
  $brokerageName: ValidString
	$brokerId: Int
	$cityIds: [Int!]
	$otpRequiredForLogin: Boolean
 ) {
  userUpdate(
   input: {
    id: $id
    email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
    phoneNo: $phoneNo
    licenseNo: $licenseNo
    isVa: $isVa
    isAdmin: $isAdmin
    isBroker: $isBroker
    approved: $approved
    brokerageName: $brokerageName
		brokerId: $brokerId
		cityIds: $cityIds
		otpRequiredForLogin: $otpRequiredForLogin
   }
  ) {
   user {
    id
   }
   errors
   message
  }
 }
`;

export const USER_MULTI_DELETE = gql`
 mutation userMultiDelete($ids: [Int!]!) {
  userMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;
export const GUEST_RESEND = gql`
 mutation guestResend($id: Int!) {
  guestResend(input: { id: $id }) {
   message
   errors
  }
 }
`;

export const CREATE_CLIENT = gql`
 mutation clientCreate(
  $firstName: ValidString
  $lastName: ValidString
  $email: ValidString
  $secondaryEmail: ValidString
  $otherEmail: ValidString
  $phone: ValidString
  $notes: ValidString
  $cityId: Int
  $currentStatus: ClientStatus
  $leaseEndDate: ValidString
  $nextFollowUp: ValidString
  $minRent: Int
  $maxRent: Int
  $availFrom: ISO8601DateTime
  $availTo: ISO8601DateTime
  $sqFeet: Int
  $bedroom: [UnitType!]
  $bathroom: Float
  $neighborhoods: [Int!]
  $addons: [ValidString!]
  $policies: [ValidString!]
  $unitFeatures: [ValidString!]
  $rating: ValidString
  $petType: ValidString
  $petNumber: Int
	$noOfParking: ValidString
  $leasedPropertyId: Int
	$roommates: [JSON!]
  $creditScore: ValidString
  $movingInspiration: ValidString
  $bio: ValidString
  $searchStatus: ValidString
  $currentResidence: ValidString
  $occupation: ValidString
  $income: Int
  $miscellaneous: ValidString
  $leadSource: ValidString
  $leadSourceDetails: ValidString
  $birthday: ISO8601Date
  $dealPropertyId: Int
  $unitNumber: ValidString
  $mls: ValidString
  $leaseSigned: ISO8601Date
  $leaseStartDate: ISO8601Date
  $dealLeaseEndDate: ISO8601Date
  $leaseTerm: Int
  $grossRent: Float
  $concession: ValidString
  $isPercentBuildingCommission: Boolean
  $buildingCommission: Float
  $netRent: Float
  $invoiceTotal: Float
  $dealCityId: Int
 ) {
  clientCreate(
   input: {
    firstName: $firstName
    lastName: $lastName
    email: $email
    secondaryEmail: $secondaryEmail
    otherEmail: $otherEmail
    phone: $phone
    notes: $notes
    cityId: $cityId
    leaseEndDate: $leaseEndDate
    currentStatus: $currentStatus
    nextFollowUp: $nextFollowUp
    minRent: $minRent
    maxRent: $maxRent
    availFrom: $availFrom
    availTo: $availTo
    sqFeet: $sqFeet
    bedroom: $bedroom
    bathroom: $bathroom
    neighborhoodIds: $neighborhoods
    addons: $addons
    policies: $policies
    unitFeatures: $unitFeatures
    rating: $rating
    petType: $petType
    petNumber: $petNumber
		noOfParking: $noOfParking
    leasedPropertyId: $leasedPropertyId
		roommates: $roommates
    creditScore: $creditScore
    movingInspiration: $movingInspiration
    bio: $bio
    searchStatus: $searchStatus
    currentResidence: $currentResidence
    occupation: $occupation
    income: $income
    miscellaneous: $miscellaneous
    leadSource: $leadSource
    leadSourceDetails: $leadSourceDetails
    birthday: $birthday
    dealPropertyId: $dealPropertyId
    unitNumber: $unitNumber
    mls: $mls
    leaseSigned: $leaseSigned
    leaseStartDate: $leaseStartDate
    dealLeaseEndDate: $dealLeaseEndDate
    leaseTerm: $leaseTerm
    grossRent: $grossRent
    concession: $concession
    isPercentBuildingCommission: $isPercentBuildingCommission
    buildingCommission: $buildingCommission
    netRent: $netRent
    invoiceTotal: $invoiceTotal
    dealCityId: $dealCityId
   }
  ) {
   client {
    id
   }
   errors
   message
	 warning
  }
 }
`;

export const UPDATE_CLIENT = gql`
 mutation clientUpdate(
  $id: Int!
  $firstName: ValidString
  $lastName: ValidString
  $email: ValidString
  $secondaryEmail: ValidString
  $otherEmail: ValidString
  $phone: ValidString
  $notes: ValidString
  $cityId: Int
  $currentStatus: ClientStatus
  $leaseEndDate: ValidString
  $nextFollowUp: ValidString
  $minRent: Int
  $maxRent: Int
  $availFrom: ISO8601DateTime
  $availTo: ISO8601DateTime
  $sqFeet: Int
  $bedroom: [UnitType!]
  $bathroom: Float
  $neighborhoods: [Int!]
  $addons: [ValidString!]
  $policies: [ValidString!]
  $unitFeatures: [ValidString!]
  $rating: ValidString
	$petType: ValidString
	$petNumber: Int
	$noOfParking: ValidString
  $leasedPropertyId: Int
	$roommates: [JSON!]
  $creditScore: ValidString
  $movingInspiration: ValidString
  $bio: ValidString
  $searchStatus: ValidString
  $currentResidence: ValidString
  $occupation: ValidString
  $income: Int
  $miscellaneous: ValidString
  $leadSource: ValidString
  $leadSourceDetails: ValidString
  $birthday: ISO8601Date
  $dealPropertyId: Int
  $unitNumber: ValidString
  $mls: ValidString
  $leaseSigned: ISO8601Date
  $leaseStartDate: ISO8601Date
  $dealLeaseEndDate: ISO8601Date
  $leaseTerm: Int
  $grossRent: Float
  $concession: ValidString
  $isPercentBuildingCommission: Boolean
  $buildingCommission: Float
  $netRent: Float
  $invoiceTotal: Float
  $dealCityId: Int
 ) {
  clientUpdate(
   input: {
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
		secondaryEmail: $secondaryEmail
    otherEmail: $otherEmail
    phone: $phone
    notes: $notes
    cityId: $cityId
    leaseEndDate: $leaseEndDate
    currentStatus: $currentStatus
    nextFollowUp: $nextFollowUp
    minRent: $minRent
    maxRent: $maxRent
    availFrom: $availFrom
    availTo: $availTo
    sqFeet: $sqFeet
    bedroom: $bedroom
    bathroom: $bathroom
    neighborhoodIds: $neighborhoods
    addons: $addons
    policies: $policies
    unitFeatures: $unitFeatures
    rating: $rating
		petType: $petType
    petNumber: $petNumber
		noOfParking: $noOfParking
    leasedPropertyId: $leasedPropertyId
		roommates: $roommates
    creditScore: $creditScore
    movingInspiration: $movingInspiration
    bio: $bio
    searchStatus: $searchStatus
    currentResidence: $currentResidence
    occupation: $occupation
    income: $income
    miscellaneous: $miscellaneous
    leadSource: $leadSource
    leadSourceDetails: $leadSourceDetails
    birthday: $birthday
    dealPropertyId: $dealPropertyId
    unitNumber: $unitNumber
    mls: $mls
    leaseSigned: $leaseSigned
    leaseStartDate: $leaseStartDate
    dealLeaseEndDate: $dealLeaseEndDate
    leaseTerm: $leaseTerm
    grossRent: $grossRent
    concession: $concession
    isPercentBuildingCommission: $isPercentBuildingCommission
    buildingCommission: $buildingCommission
    netRent: $netRent
    invoiceTotal: $invoiceTotal
    dealCityId: $dealCityId
   }
  ) {
   client {
    id
   }
   errors
   message
  }
 }
`;

export const UPDATE_CLIENT_STATUS = gql`
 mutation updateClientStatus($id: Int!, $currentStatus: ClientStatus!) {
  updateClientStatus(input: { id: $id, currentStatus: $currentStatus }) {
   client {
    id
		name
		currentStatus
   }
   errors
   message
  }
 }
`;

export const UPDATE_CLIENT_NEXT_FOLLOW_UP = gql`
 mutation updateClientNextFollowUp($id: Int!, $nextFollowUp: ValidString!) {
  updateClientNextFollowUp(input: { id: $id, nextFollowUp: $nextFollowUp }) {
   client {
    id
		name
		nextFollowUp
   }
   errors
   message
  }
 }
`;

export const CLIENT_MULTI_DELETE = gql`
 mutation clientMultiDelete($ids: [Int!]!) {
  clientMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const CLIENT_MULTI_DISCARD = gql`
 mutation clientMultiDiscard($ids: [Int!]!) {
  clientMultiDiscard(input: { ids: $ids }) {
   discardCount
   errors
   message
  }
 }
`;
export const CLIENT_MULTI_UNDISCARD = gql`
 mutation clientMultiUndiscard($ids: [Int!]!) {
  clientMultiUndiscard(input: { ids: $ids }) {
   undiscardCount
   errors
   message
  }
 }
`;

export const CREATE_NOTIFICATION = gql`
 mutation notificationCreate($title: ValidString!, $description: ValidString!, $notificationType: NotificationType!) {
  notificationCreate(input: { title: $title, description: $description, notificationType: $notificationType }) {
   notification {
    id
   }
   errors
  }
 }
`;

export const UPDATE_PROFILE = gql`
 mutation userProfileUpdate(
  $email: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $phoneNo: ValidString
  $licenseNo: ValidString
  $cityId: Int
  $brokerageName: ValidString
  $signature: ValidString
  $logo: File
  $avatar: File
  $brokerageInfo: File
  $brokerageInfoUrl: String
  $deleteLogo: Boolean
  $deleteAvatar: Boolean
  $deleteBrokerageInfo: Boolean
  $street1: ValidString
  $street2: ValidString
  $cityName: ValidString
  $stateName: ValidString
  $zip: ValidString
  $otpRequiredForLogin: Boolean
  $urlName: ValidString
  $facebook: ValidString
  $instagram: ValidString
  $linkedin: ValidString
  $twitter: ValidString
  $calendly: ValidString
	$tiktok: ValidString
	$youtube: ValidString
	$pinterest: ValidString
	$whatsapp: ValidString
	$wechat: ValidString
	$googlePage: ValidString
	$personalWebsite: ValidString
	$aboutMe: String
 ) {
  userProfileUpdate(
   input: {
    email: $email
    firstName: $firstName
    lastName: $lastName
    phoneNo: $phoneNo
    licenseNo: $licenseNo
    cityId: $cityId
    signature: $signature
    brokerageName: $brokerageName
    logo: $logo
    avatar: $avatar
		brokerageInfo: $brokerageInfo
		brokerageInfoUrl: $brokerageInfoUrl
    deleteLogo: $deleteLogo
    deleteAvatar: $deleteAvatar
		deleteBrokerageInfo: $deleteBrokerageInfo
		street1: $street1
		street2: $street2
		cityName: $cityName
		stateName: $stateName
		zip: $zip
		otpRequiredForLogin: $otpRequiredForLogin
		urlName: $urlName
		facebook: $facebook
		instagram: $instagram
		linkedin: $linkedin
		twitter: $twitter
		calendly: $calendly
		tiktok: $tiktok 
		youtube: $youtube 
		pinterest: $pinterest 
		whatsapp: $whatsapp 
		wechat: $wechat 
		googlePage: $googlePage 
		personalWebsite: $personalWebsite 
		aboutMe: $aboutMe
   }
  ) {
   errors
   message
  }
 }
`;

export const GUEST_HISTORY_MULTI_DELETE = gql`
 mutation guestMultiDelete($ids: [Int!]!) {
  guestMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const TOUR_REQUEST_HISTORY_MULTI_DELETE = gql`
 mutation deleteTourRequests($ids: [Int!]!) {
  deleteTourRequests(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const REPORTS_MULTI_DELETE = gql`
 mutation reportMultiDelete($ids: [Int!]!) {
  reportMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const NOTIFICATION_MULTI_DELETE = gql`
 mutation notificationMultiDelete($ids: [Int!]!) {
  notificationMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const LEADS_MULTI_DELETE = gql`
 mutation leadMultiDelete($ids: [Int!]!) {
  leadMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const PROPERTY_CREATE = gql`
 mutation propertyCreate(
  $name: ValidString!
  $isConfirmed: Boolean!
  $incomeRequirementLevel: ValidString
  $phone: ValidString
  $specials: ValidString
  $price: Float
  $neighborhood: ValidString!
  $zip: ValidString
  $builtYear: Int
  $escort: Float
  $managementCompany: ValidString
  $webLink: ValidString
  $email: ValidString
  $googleRating: ValidString
  $googleMap: ValidString
  $cityId: Int!
  $lat: Float
  $long: Float
  $address: ValidString
  $photoGalleryLink: ValidString
  $floorPlanLink: ValidString
  $googleReviewLink: ValidString
  $sendEscort: Float
  $renovated: Int
  $bathroom: ValidString
  $bonus: ValidString
  $commissionNote: ValidString
  $note: ValidString
  $units: Int
  $addonList: [ValidString!]
  $policyList: [ValidString!]
  $virtualTourLink: ValidString
	$virtualTours: ValidString
  $parkingNote: ValidString
  $privateNote: ValidString
  $invoicingNote: ValidString
  $managementConfirmation: ValidString
  $specialsAndCommissionPriority: ValidString
  $communicationMethod: ValidString
  $manualPricingFrequency: ValidString
 ) {
  propertyCreate(
   input: {
    name: $name
    isConfirmed: $isConfirmed
    incomeRequirementLevel: $incomeRequirementLevel
    phone: $phone
    specials: $specials
    price: $price
    neighborhood: $neighborhood
    zip: $zip
    builtYear: $builtYear
    escort: $escort
    managementCompany: $managementCompany
    webLink: $webLink
    email: $email
    googleRating: $googleRating
    googleMap: $googleMap
    cityId: $cityId
    lat: $lat
    long: $long
    address: $address
    photoGalleryLink: $photoGalleryLink
    floorPlanLink: $floorPlanLink
    googleReviewLink: $googleReviewLink
    sendEscort: $sendEscort
    renovated: $renovated
    bathroom: $bathroom
    bonus: $bonus
    commissionNote: $commissionNote
    note: $note
    units: $units
    addonList: $addonList
    policyList: $policyList
    virtualTourLink: $virtualTourLink
		virtualTours: $virtualTours
		parkingNote: $parkingNote
		privateNote: $privateNote
		invoicingNote: $invoicingNote
    managementConfirmation: $managementConfirmation
    specialsAndCommissionPriority: $specialsAndCommissionPriority
    communicationMethod: $communicationMethod
    manualPricingFrequency: $manualPricingFrequency
   }
  ) {
   property {
    city {
     id
     name
    }
    id
    name
    email
    address
    phone
    lat
    long
    units
    renovated
    zip
    builtYear
    managementCompany
    managementConfirmation
    googleMap
    googleRating
    googleReviewLink
    photoGalleryLink
    floorPlanLink
    neighborhood {
     id
     name
    }
    webLink
    specials
    escort
    sendEscort
    bonus
    commissionNote
    imageThumbUrls
    imageUrls
    imageIds
    addonItems
    policyItems
    updatedAt
    isConfirmed
    incomeRequirementLevel
    virtualTourLink
		virtualTours
		parkingNote
		privateNote
		invoicingNote
    specialsAndCommissionPriority
    manualPricingFrequency
    communicationMethod
    floorPlans {
     id
     name
     bed
     bath
     price
     sqft
     description
     virtualTourLink
     plan2d
     plan3d
     imageUrls
     imageIds
     unitFeatures
    }
    typeDetails {
     id
     floorPlan {
      id
      bed
      bath
      name
      unitFeatures
     }
     moveIn
     price
     size
     aptNo
     isAvailable
     addonItems
     updatedAt
    }
   }
   message
   errors
  }
 }
`;

export const PROPERTY_UPDATE = gql`
 mutation propertyUpdate(
  $id: Int!
  $name: ValidString
  $slug: ValidString
  $isConfirmed: Boolean!
  $autoUpdate: Boolean
  $incomeRequirementLevel: ValidString
  $phone: ValidString
  $specials: ValidString
  $price: Float
  $neighborhood: ValidString!
  $zip: ValidString
  $builtYear: Int
  $escort: Float
  $managementCompany: ValidString
  $webLink: ValidString
  $email: ValidString
  $googleRating: ValidString
  $googleMap: ValidString
  $cityId: Int!
  $lat: Float
  $long: Float
  $address: ValidString
  $photoGalleryLink: ValidString
  $floorPlanLink: ValidString
  $googleReviewLink: ValidString
  $sendEscort: Float
  $renovated: Int
  $bathroom: ValidString
  $bonus: ValidString
  $commissionNote: ValidString
  $note: ValidString
  $units: Int
  $addonList: [ValidString!]
  $policyList: [ValidString!]
  $virtualTourLink: ValidString
  $virtualTours: ValidString
  $parkingNote: ValidString
  $privateNote: ValidString
  $invoicingNote: ValidString
  $managementConfirmation: ValidString
  $specialsAndCommissionPriority: ValidString
  $communicationMethod: ValidString
  $manualUpdate: Boolean
  $noCommission: Boolean
  $amenitiesConfirmed: Boolean
  $policyNote: ValidString
  $manualPricingFrequency: ValidString
 ) {
  propertyUpdate(
   input: {
    id: $id
    name: $name
    slug: $slug
    isConfirmed: $isConfirmed
    autoUpdate: $autoUpdate
    incomeRequirementLevel: $incomeRequirementLevel
    phone: $phone
    specials: $specials
    price: $price
    neighborhood: $neighborhood
    zip: $zip
    builtYear: $builtYear
    escort: $escort
    managementCompany: $managementCompany
    webLink: $webLink
    email: $email
    googleRating: $googleRating
    googleMap: $googleMap
    cityId: $cityId
    lat: $lat
    long: $long
    address: $address
    photoGalleryLink: $photoGalleryLink
    floorPlanLink: $floorPlanLink
    googleReviewLink: $googleReviewLink
    sendEscort: $sendEscort
    renovated: $renovated
    bathroom: $bathroom
    bonus: $bonus
    commissionNote: $commissionNote
    note: $note
    units: $units
    addonList: $addonList
    policyList: $policyList
    virtualTourLink: $virtualTourLink
		virtualTours: $virtualTours
		parkingNote: $parkingNote
		privateNote: $privateNote
		invoicingNote: $invoicingNote
    managementConfirmation: $managementConfirmation
    specialsAndCommissionPriority: $specialsAndCommissionPriority
    communicationMethod: $communicationMethod
    manualUpdate: $manualUpdate
    noCommission: $noCommission
    amenitiesConfirmed: $amenitiesConfirmed
    policyNote: $policyNote
    manualPricingFrequency: $manualPricingFrequency
   }
  ) {
   property {
    id
   }
   message
   errors
  }
 }
`;

export const PROPERTY_MULTI_UPDATE = gql`
 mutation propertiesMultiUpdate($updateProperties: [PropertyUpdate!]!) {
  propertiesMultiUpdate(input: { updateProperties: $updateProperties }) {
   properties {
    city {
     id
     name
    }
    id
    name
    email
    address
    phone
    lat
    long
    units
    renovated
    zip
    builtYear
    managementCompany
    managementConfirmation
    googleMap
    googleRating
    googleReviewLink
    photoGalleryLink
    floorPlanLink
    neighborhood {
     id
     name
    }
    webLink
    specials
    escort
    sendEscort
    bonus
    commissionNote
    imageThumbUrls
    imageUrls
    imageIds
    addonItems
    policyItems
    updatedAt
    isConfirmed
    incomeRequirementLevel
    virtualTourLink
		additionalNote
		schedulingNote
		virtualTours
		parkingNote
		privateNote
		invoicingNote
    specialsAndCommissionPriority
    manualPricingFrequency
    communicationMethod
    floorPlans {
     id
     name
     bed
     bath
     price
     sqft
     description
     virtualTourLink
     plan2d
     plan3d
     imageUrls
     imageIds
     unitFeatures
    }
    typeDetails {
     id
     floorPlan {
      id
      bed
      bath
      name
      unitFeatures
     }
     moveIn
     price
     size
     aptNo
     isAvailable
     addonItems
     updatedAt
    }
   }
   message
   errors
  }
 }
`;

export const PROPERTY_UPDATE_PHOTOS = gql`
 mutation propertyUpdatePhotos(
  $id: Int!
  $images: [File!]
  $imageIdsToRemove: [Int!]
 ) {
  propertyUpdatePhotos(
   input: { id: $id, images: $images, imageIdsToRemove: $imageIdsToRemove }
  ) {
   property {
    id
   }
   message
   errors
  }
 }
`;

export const PROPERTY_MULTI_DELETE = gql`
 mutation propertyMultiDelete($ids: [Int!]!) {
  propertyMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const UNIT_MULTI_CREATE = gql`
 mutation unitMultiCreate($createUnits: [UnitCreate!]!) {
  unitMultiCreate(input: { createUnits: $createUnits }) {
   units {
    id
    floorPlan {
     id
     bed
     bath
     name
     unitFeatures
    }
    moveIn
    aptNo
    price
    size
    isAvailable
    addonItems
    updatedAt
   }
   message
   errors
  }
 }
`;

export const UNIT_MULTI_UPDATE = gql`
 mutation unitMultiUpdate($updateUnits: [UnitUpdate!]!) {
  unitMultiUpdate(input: { updateUnits: $updateUnits }) {
   units {
    id
    floorPlan {
     id
     bed
     bath
     name
     unitFeatures
    }
    moveIn
    aptNo
    price
    size
    isAvailable
    addonItems
    updatedAt
   }
   message
   errors
  }
 }
`;

export const UNIT_MULTI_DELETE = gql`
 mutation unitMultiDelete($ids: [Int!]!) {
  unitMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const FLOORPLAN_MULTI_CREATE = gql`
 mutation floorPlanMultiCreate($createFloorPlans: [FloorPlanCreate!]!) {
  floorPlanMultiCreate(input: { createFloorPlans: $createFloorPlans }) {
   floorPlans {
    id
    name
    bed
    bath
    price
    sqft
    description
    virtualTourLink
    plan2d
    plan3d
    imageUrls
    imageIds
    unitFeatures
   }
   message
   errors
  }
 }
`;

export const FLOORPLAN_MULTI_UPDATE = gql`
 mutation floorPlanMultiUpdate($updateFloorPlans: [FloorPlanUpdate!]!) {
  floorPlanMultiUpdate(input: { updateFloorPlans: $updateFloorPlans }) {
   floorPlans {
    id
    name
    bed
    bath
    price
    sqft
    description
    virtualTourLink
    plan2d
    plan3d
    imageUrls
    imageIds
    unitFeatures
   }
   message
   errors
  }
 }
`;

export const FLOORPLAN_MULTI_DELETE = gql`
 mutation floorPlanMultiDelete($ids: [Int!]!) {
  floorPlanMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const CITY_CREATE = gql`
 mutation cityCreate(
  $name: ValidString!
  $stateName: ValidString!
  $timeZone: ValidString!
  $isVisible: Boolean!
 ) {
  cityCreate(
   input: { name: $name, stateName: $stateName, timeZone: $timeZone, isVisible: $isVisible }
  ) {
   errors
   message
  }
 }
`;

export const CITY_UPDATE = gql`
 mutation cityUpdate(
  $id: Int!
  $name: ValidString!
  $stateName: ValidString!
  $timeZone: ValidString!
  $isVisible: Boolean!
 ) {
  cityUpdate(
   input: { id: $id, name: $name, stateName: $stateName, timeZone: $timeZone isVisible: $isVisible }
  ) {
   errors
   message
  }
 }
`;

export const CITY_DELETE = gql`
 mutation cityMultiDelete($ids: [Int!]!) {
  cityMultiDelete(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
`;

export const PLAN_CREATE = gql`
 mutation planCreate(
  $name: ValidString!
  $amount: Float!
  $stripePlanId: ValidString
  $canTrial: Boolean!
  $interval: ValidString
  $isVisible: Boolean!
  $maxCities: Int!
 ) {
  planCreate(
   input: {
    name: $name
    amount: $amount
    stripePlanId: $stripePlanId
    canTrial: $canTrial
    interval: $interval
    isVisible: $isVisible
    maxCities: $maxCities
   }
  ) {
   errors
   message
  }
 }
`;

export const PLAN_UPDATE = gql`
 mutation planUpdate(
  $id: Int!
  $name: ValidString!
  $amount: Float!
  $stripePlanId: ValidString
  $canTrial: Boolean!
  $interval: ValidString
  $isVisible: Boolean!
  $maxCities: Int!
 ) {
  planUpdate(
   input: {
    id: $id
    name: $name
    amount: $amount
    stripePlanId: $stripePlanId
    canTrial: $canTrial
    interval: $interval
    isVisible: $isVisible
    maxCities: $maxCities
   }
  ) {
   errors
   message
  }
 }
`;

export const SEND_REPORT = gql`
 mutation reportCreate(
  $title: ValidString!
  #$propertyIds: [Int!]!
  $reportProperties: [ReportPropertyInput!]!
  $newClient: Boolean!
  $cityId: Int
  $clientFirstName: ValidString
  $clientLastName: ValidString
  $clientPhone: ValidString
  $clientEmail: ValidString
  $clientId: Int
  $message: ValidString
  $hidePropertyName: Boolean
 ) {
  reportCreate(
   input: {
    title: $title
    #propertyIds: $propertyIds
    reportProperties: $reportProperties
    newClient: $newClient
    clientFirstName: $clientFirstName
    clientLastName: $clientLastName
    clientPhone: $clientPhone
    clientEmail: $clientEmail
    clientId: $clientId
    cityId: $cityId
    message: $message
    hidePropertyName: $hidePropertyName
   }
  ) {
   report {
    id
   }
   message
   errors
	 warning
  }
 }
`;

export const RESEND_REPORT = gql`
	mutation resendReport($id: Int!) {
		resendReport(input: { id: $id }) {
			errors
			message
			report{
				title
				message
				isShow
        hidePropertyName
			}
		}
	}
`;

export const SAVE_CLIENT_RATING_NOTES = gql`
 mutation reportSaveClientRatingNotes(
  $reportId: Int!
  $propertyId: Int!
  $floorPlanId: Int
  $typeDetailId: Int
  $clientRating: ClientRating!
  $clientNotes: ValidString
 ) {
  reportSaveClientRatingNotes(
   input: {
    reportId: $reportId
    reportProperty: {
     propertyId: $propertyId
     floorPlanId: $floorPlanId
     typeDetailId: $typeDetailId
    }
    clientRating: $clientRating
    clientNotes: $clientNotes
   }
  ) {
   reportProperty {
		id
    clientNotes
    clientRating
    floorPlanId
    propertyId
    typeDetailId
		isSelectedByAgent
   }
   message
   errors
  }
 }
`;

export const PASSWORD_RESET = gql`
 mutation resetPassword($resetToken: ValidString!, $newPassword: ValidString!) {
  resetPassword(input: { resetToken: $resetToken, newPassword: $newPassword }) {
   user {
    id
    name
   }
   message
   errors
  }
 }
`;

export const CREATE_GUEST_CARD = gql`
 mutation guestCreate(
  $email: ValidString
  $firstName: ValidString!
  $lastName: ValidString!
  $phone: ValidString
  $budget: ValidString
  $petNumber: ValidString
  $petType: ValidString
  $petName: ValidString
  $moveIn: ValidString
  $bedroomSize: ValidString
  $bathroomSize: ValidString
  $communities: [ValidString!]
  $preferences: ValidString
  $appointmentType: AppointmentType
  $tourAt: ValidString
 ) {
  guestCreate(
   input: {
    email: $email
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    budget: $budget
    petNumber: $petNumber
    petType: $petType
    moveIn: $moveIn
    petName: $petName
    bedroomSize: $bedroomSize
    bathroomSize: $bathroomSize
    communities: $communities
    preferences: $preferences
    appointmentType: $appointmentType
    tourAt: $tourAt
   }
  ) {
   guest {
    id
   }
   message
   errors
  }
 }
`;

export const CREATE_TOUR_REQUEST = gql`
 mutation createTourRequest(
  $propertyId: Int!
  $cityId: Int
  $clientId: Int!
  $tourAt: ValidString!
  $appointmentType: AppointmentType
  $status: TourRequestStatus
 ) {
  createTourRequest(
   input: {
    propertyId: $propertyId
    cityId: $cityId
    clientId: $clientId
    tourAt: $tourAt
    appointmentType: $appointmentType
    status: $status
   }
  ) {
   tourRequest {
    client {
     name
    }
    property {
     name
    }
    tourAt
   }
	 message
	 errors
  }
 }
`;

export const GUEST_CARD_PDF = gql`
 mutation guestPdf($id: Int!) {
  guestPdf(input: { id: $id }) {
   errors
   message
   guestPdfUrl
  }
 }
`;

export const APPROVE_DECLINE_USER = gql`
 mutation userApproveDecline($approve: Boolean!, $userId: Int!) {
  userApproveDecline(input: { approve: $approve, userId: $userId }) {
   user {
    id
    #status
    approved
   }
   message
   errors
  }
 }
`;

export const REQUEST_TOUR = gql`
 mutation reportRequestTour(
  $startDate: ValidString!
  $agentEmail: ValidString!
  $name: ValidString!
  $clientName: ValidString!
 ) {
  reportRequestTour(
   input: {
    startDate: $startDate
    agentEmail: $agentEmail
    name: $name
    clientName: $clientName
   }
  ) {
   message
   errors
  }
 }
`;
export const SUBSCRIPTION_CREATE = gql`
 mutation subscriptionCreate(
  $stripeToken: ValidString!
  $stripePlanId: ValidString!
  $cityIds: [Int!]!
  $coupon: ValidString
 ) {
  subscriptionCreate(
   input: {
    stripeToken: $stripeToken
    stripePlanId: $stripePlanId
    cityIds: $cityIds
    coupon: $coupon
   }
  ) {
   subscription {
    active
    plan {
     name
    }
   }
   message
   errors
  }
 }
`;

export const SUBSCRIPTION_DELETE = gql`
 mutation subscriptionDelete($id: Int!, $cancelReason: String!) {
  subscriptionDelete(input: { id: $id, cancelReason: $cancelReason }) {
   errors
   message
   subscription {
    id
    active
    isTrial
    currentStartDatetime
    currentEndDatetime
    status
    stripeSubscriptionId
    cities {
     id
     name
    }

    plan {
     id
     name
     interval
     amount
     canTrial
     interval
     isVisible
     maxCities
    }
   }
  }
 }
`;

export const REQUEST_CANCEL_SUBSCRIPTION = gql`
	mutation requestCancelSubscription($subscriptionId: Int!, $cancelReason: String!){
		requestCancelSubscription(input: { subscriptionId: $subscriptionId, cancelReason: $cancelReason }) {
			errors
			message
		}
	}
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
 mutation subscriptionUpdate(
  $id: Int!
  $userId: Int!
  $planId: Int!
  $currentStartDatetime: ValidString!
  $currentEndDatetime: ValidString!
  $active: Boolean!
  $isTrial: Boolean!
  $cityIds: [Int!]!
  $coupon: ValidString
 ) {
  subscriptionUpdate(
   input: {
    id: $id
    userId: $userId
    planId: $planId
    currentStartDatetime: $currentStartDatetime
    currentEndDatetime: $currentEndDatetime
    active: $active
    isTrial: $isTrial
    cityIds: $cityIds
    coupon: $coupon
   }
  ) {
   subscription {
    id
    active
    isTrial
    currentStartDatetime
    currentEndDatetime
    status
    stripeSubscriptionId
    cities {
     id
     name
    }
    plan {
     id
     name
     interval
     amount
     canTrial
     interval
     isVisible
     maxCities
    }
   }
   errors
   message
  }
 }
`;

export const SUBSCRIPTION_CREATE_DIRECT = gql`
 mutation subscriptionCreateDirect(
  $userId: Int!
  $planId: Int!
  $currentStartDatetime: ValidString!
  $currentEndDatetime: ValidString!
  $active: Boolean!
  $isTrial: Boolean!
  $cityIds: [Int!]!
 ) {
  subscriptionCreateDirect(
   input: {
    userId: $userId
    planId: $planId
    currentStartDatetime: $currentStartDatetime
    currentEndDatetime: $currentEndDatetime
    active: $active
    isTrial: $isTrial
    cityIds: $cityIds
   }
  ) {
   subscription {
    id
    active
    isTrial
    currentStartDatetime
    currentEndDatetime
    status
    stripeSubscriptionId
    cities {
     id
     name
    }
    plan {
     id
     name
     interval
     amount
     canTrial
     interval
     isVisible
     maxCities
    }
   }
   errors
   message
  }
 }
`;

export const CREATE_SUBSCRIPTION_WITH_SIGNUP = gql`
 mutation createSubscriptionWithSignup(
  $email: ValidString!
  $password: ValidString!
  $firstName: ValidString!
  $lastName: ValidString!
  $phoneNo: ValidString
  $licenseNo: ValidString
  $planId: Int!
  $cityIds: [Int!]!
  $stripeToken: ValidString!
  $coupon: ValidString
	$affiliateCode: ValidString
 ) {
  createSubscriptionWithSignup(
   input: {
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    phoneNo: $phoneNo
    licenseNo: $licenseNo
    planId: $planId
    cityIds: $cityIds
    stripeToken: $stripeToken
    coupon: $coupon
		affiliateCode: $affiliateCode
   }
  ) {
   subscription {
    active
    plan {
     name
    }
   }
   user {
    name
   }
   errors
   message
  }
 }
`;

export const TOUR_REQUEST_PDF = gql`
mutation tourRequestPdf ($id: Int!){
  tourRequestPdf (input: {id: $id}){
    guestPdfUrl
    errors
    message
  }
}`;

export const TOUR_REQUEST_RESEND = gql`
mutation tourRequestResend ($id: Int!){
  tourRequestResend (input: {id: $id}){
    tourRequest{
      id
    }
    message
    errors
  }
}`;

export const CREATE_INVOICE = gql`
	mutation createInvoice($propertyId: Int!, $clientId: Int!, $moveIn: ValidString, $unitNumber: ValidString, $invoiceNumber: ValidString, $leaseTerm: Int, $leaseStartDate: ValidString, $leaseAmount: Int, $commission: Float, $status: InvoiceStatus, $concession: ValidString, $isPercentCommission: Boolean, $mls: ValidString, $leasingAgentName: ValidString, $referralAgentName: ValidString, $referralAgentEmail: ValidString, $referralCommission: Float, $isPercentReferralCommission: Boolean, $checkNumber: ValidString, $checkDate: ValidString, $notes: ValidString){
		createInvoice(input: { propertyId: $propertyId, clientId: $clientId, moveIn: $moveIn, unitNumber: $unitNumber, invoiceNumber: $invoiceNumber, leaseTerm: $leaseTerm, leaseStartDate: $leaseStartDate, leaseAmount: $leaseAmount, commission: $commission, concession: $concession, status: $status, isPercentCommission: $isPercentCommission, mls: $mls, leasingAgentName: $leasingAgentName, referralAgentName: $referralAgentName, referralAgentEmail: $referralAgentEmail, referralCommission: $referralCommission, isPercentReferralCommission: $isPercentReferralCommission, checkNumber: $checkNumber, checkDate: $checkDate, notes: $notes}) {
			invoice {
				id
				user{
					id
					name
				}
				client{
					id
					name
				}
				property{
					id
					name
				}
				commission
				createdAt
				leaseAmount
				leaseStartDate
				leaseTerm
				moveIn
				paidOn
				status
				submittedOn
				updatedAt
        mls
        leasingAgentName
        referralAgentName
        referralAgentEmail
        referralCommission
        isPercentReferralCommission
        checkNumber
        checkDate
        notes
			}
			errors
			message
		}
	}
`;

export const UPDATE_INVOICE = gql`
mutation updateInvoice ($id: Int!, $propertyId: Int, $clientId: Int, $moveIn: ValidString, $unitNumber: ValidString, $invoiceNumber: ValidString, $leaseTerm: Int, $leaseStartDate: ValidString, $leaseAmount: Int, $commission: Float, $isSubmit: Boolean, $isPercentCommission: Boolean, $concession: ValidString, $sendTo: ValidString, $mls: ValidString, $leasingAgentName: ValidString, $referralAgentName: ValidString, $referralAgentEmail: ValidString, $referralCommission: Float, $isPercentReferralCommission: Boolean, $checkNumber: ValidString, $checkDate: ValidString, $notes: ValidString){
  updateInvoice(input: { id: $id, propertyId: $propertyId, clientId: $clientId, moveIn: $moveIn, unitNumber: $unitNumber, invoiceNumber: $invoiceNumber, leaseTerm: $leaseTerm, leaseStartDate: $leaseStartDate, leaseAmount: $leaseAmount, commission: $commission, isSubmit: $isSubmit, isPercentCommission: $isPercentCommission, concession: $concession, sendTo: $sendTo, mls: $mls, leasingAgentName: $leasingAgentName, referralAgentName: $referralAgentName, referralAgentEmail: $referralAgentEmail, referralCommission: $referralCommission, isPercentReferralCommission: $isPercentReferralCommission, checkNumber: $checkNumber, checkDate: $checkDate, notes: $notes}) {
    errors
    invoice {
      id
			invoiceNumber
      client {
        name
      }
      status
      submittedOn
      paidOn
      mls
      leasingAgentName
      referralAgentName
      referralAgentEmail
      referralCommission
      isPercentReferralCommission
      checkNumber
      checkDate
      notes
    }
    message
  }
}`;


export const INVOICE_HISTORY_MULTI_DELETE = gql`
mutation invoicesMultiDelete ($ids: [Int!]!){
  invoicesMultiDelete(input: { ids: $ids }) {
    clientMutationId
    deleteCount
    errors
    message
  }
}`;

export const UPDATE_INVOICE_STATUS = gql`
	mutation updateInvoiceStatus($id: Int!, $newStatus: ValidString!){
		updateInvoiceStatus(input: { id: $id, newStatus: $newStatus }) {
			clientMutationId
			invoice {
				id
				invoiceNumber
				status
				submittedOn
				paidOn
			}
			message
			errors
		}
	}
`;

export const SEND_INVOICE = gql`
	mutation sendInvoice($id: Int!){
		sendInvoice(input: { id: $id }) {
			clientMutationId
			invoice {
				id
				invoiceNumber
				status
				paidOn
				submittedOn
			}
			message
			errors
		}
	}
`;

export const DOWNLOAD_INVOICE = gql`
mutation downloadInvoice ($id: Int!){
  downloadInvoice(input: { id: $id }) {
    errors
    invoiceDownloadLink
    message
  }
}`;

export const DELETE_REPORT_PROPERTY = gql`
mutation deleteReportProperty($ids: [Int!]!, $reportId: Int!, $clientId: Int!){
  deleteReportProperty(input: {ids: $ids, reportId: $reportId, clientId: $clientId}) {
    clientMutationId
    errors
    message
  }
}`;

export const UPDATE_ATTACHMENT = gql`
mutation updateAttachment($id: Int!, $name: String!, $attachable: File, $isRemove: Boolean){
  updateAttachment(input: { id: $id, name: $name, attachable: $attachable, isRemove: $isRemove }) {
    errors
    message
  }
}`;

export const UPDATE_FLOORPLAN_IMG = gql`
mutation floorPlanImgUpdate($id: Int!, $floorPlanImg: File, $removeImg: Boolean){
  floorPlanImgUpdate(input: { id: $id, floorPlanImg: $floorPlanImg, removeImg: $removeImg }) {
    clientMutationId
    errors
    message
  }
}`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
mutation updateNotificationSettings($sentListOpenEmail: Boolean!, $sentListOpenSms: Boolean!, $sentListRatingEmail: Boolean!, $sentListRatingSms: Boolean!){
  updateNotificationSettings(input: {sentListOpenEmail: $sentListOpenEmail, sentListOpenSms: $sentListOpenSms, sentListRatingEmail: $sentListRatingEmail, sentListRatingSms: $sentListRatingSms}){
    error
    message
  }
}`;

export const UPDATE_TOUR_REQUEST = gql`
mutation updateTourRequest(
	$id: Int!, 
	$tourAt: ValidString!, 
	$appointmentType:AppointmentType!, 
	$status: TourRequestStatus,
	$emailGuestCard: Boolean
	){
  updateTourRequest(input: { 
		id: $id, 
		tourAt: $tourAt, 
		appointmentType: $appointmentType, 
		status: $status, 
		emailGuestCard: $emailGuestCard
	}) {
    errors
    message
    tourRequest {
      id
      tourAt
      client{
        name
      }
      city {
        name
      }
    }
  }
}
`;

export const UPDATE_FLOORPLANS_ON_UNIT_CHANGE = gql`

mutation ($propertyId: Int!, $forAvailableUnitsOnly: Boolean, $autoUpdate: Boolean){
  updateFloorPlansSizeRentAvailability(input: { propertyId: $propertyId, forAvailableUnitsOnly: $forAvailableUnitsOnly, autoUpdate: $autoUpdate}) {
    clientMutationId
    errors
    message
    property {
      floorPlans{
        id
        name
        availableFrom
        availableTo
        sqftMax
        sqftMin
        sqft
        isAvailable
      }
      id
      name 
    }
  }
}
`;

export const DEACTIVATE_AGENTS = gql`
mutation deactivateAgents($ids: [Int!]!){
  deactivateAgents(input: { ids: $ids }) {
    deactivateCount
    errors
    message
  }
}`;

export const REACTIVATE_AGENTS = gql`
mutation reactivateAgents($ids: [Int!]!){
  reactivateAgents(input: { ids: $ids }) {
    reactivateCount
    errors
    message
  }
}`;

export const DELETE_AGENTS = gql`
mutation deleteAgents($ids: [Int!]!){
  deleteAgents(input: { ids: $ids }) {
    deleteCount
    errors
    message
  }
}`;

export const MANAGE_AGENT_CITY_ACCESS = gql`
	mutation manageAgentCityAccess($brokerId: Int, $subscriptionId: Int!, $agentId: Int!, $cityIds: [Int!]!){
		manageAgentCityAccess(
			input: { brokerId: $brokerId, subscriptionId: $subscriptionId, agentId: $agentId, cityIds: $cityIds }
		) {
			cities {
				id
				name
			}
			errors
			message
		}
	}
`;

export const CHANGE_PAYMENT_METHOD = gql`
	mutation changePaymentMethod($stripeToken: ValidString!, $currentPm: ValidString!) {
		changePaymentMethod(input: {stripeToken: $stripeToken, currentPm: $currentPm}) {
			errors
			message
		}
	}
`;

export const SEND_OTP = gql`
	mutation sendOtp($email: ValidString!) {
		sendOtp(input: {email: $email}) {
			errors
			message
			requireOtpConfirmation
		}
	}
`;

export const VALIDATE_OTP = gql`
	mutation validateOtp($email: ValidString!, $otp: ValidString!){
		validateOtp(input: {email: $email, otp: $otp}) {
			errors
			message
		}
	}
`;

export const REFRESH_TOKEN = gql`
	mutation refreshAuthToken($authToken: String!, $refreshToken: String){
		refreshAuthToken(input: { authToken: $authToken, refreshToken: $refreshToken }) {
			authToken
			refreshToken
		}
	}
`;

export const GENERATE_API_KEY = gql`
	mutation generateApiKey($userId: Int!){
		generateApiKey(input: { userId: $userId }) {
			errors
			message
		}
	}
`;

export const EXPIRE_API_KEY = gql`
	mutation expireApiKey($userId: Int!){
		expireApiKey(input: { userId: $userId }) {
			errors
			message
		}
	}
`;

export const CREATE_CLIENT_INQUIRY = gql`
	mutation createClientInquiry(
		$urlName: String!, 
		$firstName: ValidString, 
		$lastName: ValidString, 
		$moveInDate: ValidString, 
		$email: ValidString, 
		$secondaryEmail: ValidString, 
		$phone: ValidString, 
		$notes: ValidString, 
		$budget: ValidString, 
		$cityId: Int, 
		$leaseEndDate: ValidString, 
		$minRent: Int, 
		$maxRent: Int, 
		$availFrom: ISO8601DateTime, 
		$availTo: ISO8601DateTime, 
		$yearFrom: Int, 
		$sqFeet: Int, 
		$bedroom: [UnitType!], 
		$bathroom: Float, 
		$neighborhoodIds: [Int!], 
		$addons: [ValidString!], 
		$policies: [ValidString!], 
		$unitFeatures: [ValidString!], 
		$rating: ValidString, 
		$petNumber: Int, 
		$petType: ValidString, 
		$noOfParking: ValidString, 
		$fromWhereYouKnowUs: ValidString, 
		$leaseTerm: Int, 
		$parkingPreferences: [ValidString!], 
		$creditScore: ValidString, 
		$background: ValidString){
		createClientInquiry(input: { 
			urlName: $urlName, 
			firstName: $firstName, 
			lastName: $lastName, 
			moveInDate: $moveInDate, 
			email: $email, 
			secondaryEmail: $secondaryEmail, 
			phone: $phone, 
			notes: $notes, 
			budget: $budget, 
			cityId: $cityId, 
			leaseEndDate: $leaseEndDate, 
			minRent: $minRent, 
			maxRent: $maxRent, 
			availFrom: $availFrom, 
			availTo: $availTo, 
			yearFrom: $yearFrom, 
			sqFeet: $sqFeet, 
			bedroom: $bedroom, 
			bathroom: $bathroom, 
			neighborhoodIds: $neighborhoodIds, 
			addons: $addons, 
			policies: $policies, 
			unitFeatures: $unitFeatures, 
			rating: $rating, 
			petNumber: $petNumber, 
			petType: $petType, 
			noOfParking: $noOfParking, 
			fromWhereYouKnowUs: $fromWhereYouKnowUs, 
			leaseTerm: $leaseTerm, 
			parkingPreferences: $parkingPreferences, 
			creditScore: $creditScore, 
			background: $background 
		}) {
			clientInquiry {
				id
			}
			errors
			message
		}
	}
`;

export const DELETE_CLIENT_INQUIRY = gql`
	mutation deleteClientInquiry($id: Int!) {
		deleteClientInquiry(input: { id: $id }) {
			errors
			message
		}
	}
`;

export const INQUIRY_TO_CLIENT = gql`
	mutation inquiryToClient($id: Int!){
		inquiryToClient(input: { id: $id }) {
			errors
			message
		}
	}
`;

export const SEND_INQUIRY_FORM = gql`
	mutation sendClientInquiryForm($emails: [String!]!, $message: String){
		sendClientInquiryForm(input: { emails: $emails, message: $message}) {
			errors
			message
		}
	}
`;

export const UPDATE_REPORT = gql`
	mutation updateReport($id: Int!, $title: ValidString, $message: ValidString, $isShow: Boolean, $hidePropertyName: Boolean) {
		updateReport(input: { id: $id, title: $title, message: $message, isShow: $isShow, hidePropertyName: $hidePropertyName}) {
			clientMutationId
			errors
			message
			report{
				title
				message
				isShow
        hidePropertyName
			}
		}
	}
`;

export const CREATE_EMAIL_TEMPLATE = gql`
	mutation createEmailTemplate($name: ValidString!, $subject: ValidString!, $sendTo: ValidString, $ccTo: ValidString, $bccTo: ValidString, $body: String!){
		createEmailTemplate(input: { name: $name, subject: $subject, sendTo: $sendTo, ccTo: $ccTo, bccTo: $bccTo, body: $body }) {
			emailTemplate {
				body
				ccTo
				createdAt
				id
				name
				sendTo
				subject
				updatedAt
        bccTo
			}
			errors
			message
		}
	}
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
	mutation updateEmailTemplate($id: Int!, $name: ValidString!, $subject: ValidString!, $sendTo: ValidString, $ccTo: ValidString, $bccTo: ValidString, $body: String!){
		updateEmailTemplate(input: { id: $id, name: $name, subject: $subject, sendTo: $sendTo, ccTo: $ccTo, bccTo: $bccTo, body: $body }) {
			emailTemplate {
				body
				ccTo
				createdAt
				id
				name
				sendTo
				subject
				updatedAt
        bccTo
			}
			errors
			message
		}
	}
`;

export const DELETE_EMAIL_TEMPLATE = gql`
	mutation deleteEmailTemplate($id: Int!){
		deleteEmailTemplate(input: { id: $id }) {
			errors
			message
		}
	}
`;

export const SEND_EMAIL = gql`
	mutation sendEmail($subject: ValidString!, $sendTo: ValidString!, $ccTo: ValidString, $bccTo: ValidString, $body: String!, $clientId: Int, $propertyId: Int, $appointmentType: AppointmentType, $tourAt: ValidString){
		sendEmail(input: { subject: $subject, sendTo: $sendTo, ccTo: $ccTo, bccTo: $bccTo, body: $body, clientId: $clientId, propertyId: $propertyId, appointmentType: $appointmentType, tourAt: $tourAt }) {
			errors
			message
		}
	}
`;

export const DRAFT_REPORT = gql`
	mutation draftReport($reportProperties: [ReportPropertyInput!]!) {
		draftReport(input: { reportProperties: $reportProperties }) {
			errors
			message
		}
	}
`;

export const DELETE_EMAIL_TEMPLATE_HISTORIES = gql`
mutation deleteEmailTemplateHistories($ids: [Int!]!) {
  deleteEmailTemplateHistories(input: { ids: $ids }) {
   deleteCount
   errors
   message
  }
 }
 `;

export const UPDATE_PROPERTY_VIEW_SETTING = gql`
mutation updatePropertyViewSetting(
  $defaultCityId: Int,
  $defaultPropertyView: String
){
  updatePropertyViewSetting(input: {
    defaultCityId: $defaultCityId,
    defaultPropertyView: $defaultPropertyView }) {
    user{
      defaultCityId,
      defaultPropertyView
    }
    errors
    message
  }
}
`;

export const NOTIFY_MISSING_PROPERTY_PHOTOS = gql`
  mutation notifyMissingPropertyPhotos($propertyId: Int!) {
    notifyMissingPropertyPhotos(input: { propertyId: $propertyId }) {
      message
      errors
    }
  }
`;

export const UPDATE_USER_PROPERTY_COLOR = gql`
  mutation UpdateUserPropertyColor($propertyId: Int!, $color: String!) {
    updateUserPropertyColor(input: { propertyId: $propertyId, color: $color }) {
      userPropertyNote {
        id
        notes
        color
      }
      message
      errors
    }
  }
`;

export const UPDATE_USER_PROPERTY_NOTES = gql`
  mutation UpdateUserPropertyNote($propertyId: Int!, $notes: String) {
    updateUserPropertyNote(input: { propertyId: $propertyId, notes: $notes }) {
      userPropertyNote {
        id
        notes
        color
      }
      message
      errors
    }
  }
`;

export const DELETE_PROPERTY_TYPE_DETAILS = gql`
  mutation deletePropertyTypeDetails($propertyId: Int!) {
    deletePropertyTypeDetails(input: { propertyId: $propertyId }) {
      message
      errors
    }
  }
`;

export const NOTIFY_PROPERTY_MISSING_EMAILS = gql`
  mutation notifyPropertyMissingEmails($propertyId: Int!) {
    notifyPropertyMissingEmails(input: { propertyId: $propertyId }) {
      message
      errors
    }
  }
`;

export const UPDATE_CLIENT_BUDGET = gql`
  mutation updateClientBudget($id: Int!, $minRent: Int, $maxRent: Int ) {
    updateClientBudget(input: { id: $id, minRent: $minRent, maxRent: $maxRent  }) {
      client {
        id
		    name
		    minRent
        maxRent
      }
      errors
      message
    }
  }
`;

export const UPDATE_MOVE_IN_DATE = gql`
  mutation updateMoveInDate($id: Int!, $availFrom: ISO8601DateTime, $availTo: ISO8601DateTime ) {
    updateMoveInDate(input: { id: $id, availFrom: $availFrom, availTo: $availTo  }) {
      client {
        id
		    name
		    availFrom
        availTo
      }
      errors
      message
    }
  }
`;

export const UPDATE_CLIENT_EMAIL = gql`
  mutation updateClientEmail($id: Int!, $email: ValidString!, $secondaryEmail: ValidString!, $otherEmail: ValidString!) {
    updateClientEmail(input: {id: $id, email: $email, secondaryEmail: $secondaryEmail, otherEmail: $otherEmail }) {
      client {
        id
        email
        secondaryEmail
        otherEmail
      }
      errors
      message
    }
  }
`;

export const UPDATE_CLIENT_PHONE = gql`
  mutation updateClientPhone($id: Int!, $phone: ValidString) {
    updateClientPhone(input: {id: $id, phone: $phone}) {
      client {
        id
        phone
      }
      errors
      message
    }
  }
`;
