import { gql } from '@apollo/client';

export const ADMIN_SUMMARY = gql`
query adminSummery{
  adminSummery
}`;

export const CONTACTINQUIRIES = gql`
  query inquiries {
    contactinquiries {
      totalCount
      edges {
        node {
          id
          email
          phone
          inquiryReason
          message
          createdAt
        }
      }
    }
  }
`;

export const SUBSCRIPTIONS = gql`
  query subscriptions {
    subscriptions {
      totalCount
      edges {
        node {
          id
          active
          stripeSubscriptionId
          user {
            email
            firstName
            lastName
            name
						isBroker
						isUser
          }
          plan {
            name
            amount
            interval
						stripePlan {
							id
							amount
							active
							interval
							tiersMode
							tiers
						}
          }
          status
          currentStartDatetime
          currentEndDatetime
        }
      }
    }
  }
`;

export const NOTIFICATIONS = gql`
  query notifications {
    notifications {
      totalCount
      edges {
        node {
          id
          title
          description
          isRead
					notificationType
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query users($search_string: ValidString) {
    users(searchString: $search_string) {
      totalCount
      edges {
        node {
          id
          name
          email
          phoneNo
          licenseNo
          isVa
          subscriptions {
            stripeSubscriptionId
            isTrial
            active
            plan {
              name
              interval
            }
          }
          isAdmin
					isBroker
					isAgent
					isUser
					brokerId
          approved
          city {
            name
          }
          #status
					affiliateCode
					isOnline
        }
      }
    }
  }
`;

export const USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phoneNo
      licenseNo
      brokerageName
      isVa
			isBroker
			brokerId
			isAgent
      approved
      subscriptions {
        id
        active
        isTrial
        currentStartDatetime
        currentEndDatetime
        status
        stripeSubscriptionId
        cities {
          id
          name
        }
        plan {
          name
          id
          amount
          interval
          maxCities
        }
      }
      isAdmin
      approved
      #status
			apiKey
			apiKeyExpiredAt
			otpRequiredForLogin
			facebook
			instagram
			linkedin
			twitter
			calendly
			tiktok
			youtube
			pinterest
			whatsapp
			wechat
			googlePage
			personalWebsite
    }
  }
`;

export const LEADS = gql`
  query leads {
    leads {
      totalCount
      pageInfo {
        endCursor
        startCursor
      }
      edges {
        node {
          id
          name
          email
          phone
          city
          budget
          reachOut
          moveIn
          bedrooms
          bathrooms
          importantToYou
        }
      }
    }
  }
`;

export const ALL_CITIES = gql`
  query allCities($urlName: String) {
    allCities(urlName: $urlName) {
      id
      name
    }
  }
`;

export const VISIBLE_CITIES = gql`
  query visibleCities {
    visibleCities {
      id
      name
    }
  }
`;

export const CITIES = gql`
  query cities {
    cities {
      id
      name
      updatedAt
      neighborhoods {
        id
        name
      }
      isVisible
      state {
        id
        name
      }
			timeZone
			jsTimeZone
      maplineUrl
    }
  }
`;

export const MY_PROFILE = gql`
  query myProfile {
    myProfile {
      name
      firstName
      lastName
      email
      phoneNo
      licenseNo
      #status
      approved
      brokerageName
      avatarUrl
      avatarThumbUrl
      logoUrl
      logoThumbUrl
			brokerageInfo
			brokerageInfoUrl
      street1
      street2
      cityName
      stateName
      zip
			sentListOpenEmail
			sentListOpenSms
			sentListRatingEmail
			sentListRatingSms
			isAgent
			upcomingInvoice
			paymentMethods
			otpRequiredForLogin
			apiKey
			apiKeyExpiredAt
			urlName
			facebook
			instagram
			linkedin
			twitter
			calendly
			tiktok
			youtube
			pinterest
			whatsapp
			wechat
			googlePage
			personalWebsite
			aboutMe
      slug
      defaultCityId
      defaultPropertyView
    }
  }
`;

export const NEIGHBORHOODS = gql`
  query neighborhoods($cityId: Int) {
    neighborhoods(cityId: $cityId) {
      id
      name
      city {
        id
      }
    }
  }
`;

export const ZIP_CODES = gql`
  query zipCodes($cityId: Int) {
    zipCodes(cityId: $cityId)
  } 
`;

export const CLIENTS = gql`
  query clients {
    clients {
      edges {
        node {
          id
          name
          firstName
          lastName
          email
          secondaryEmail
          otherEmail
          phone
          budget
          status
					currentStatus
          leaseEndDate
          notes
          addonsArray
          availFrom
          availTo
          sqFeet
          bathroom
          unitFeaturesArray
          neighborhoodIdsArray
          minRent
          maxRent
          policiesArray
          bedroomArray
          nextFollowUp
          rating
          noOfParking
          city {
            id
            name
          }
          report {
            title
            message
            hidePropertyName
          }
					discardedAt
					createdAt
        }
      }
      totalCount
    }
  }
`;

export const CLIENTS_CITY_WISE = gql`
  query clients($cityId: Int!) {
    clients(cityId: $cityId) {
      edges {
        node {
          id
          name
          email
          phone
          budget
          status
          moveInDate
          city {
            name
          }
        }
      }
    }
  }
`;

export const CLIENT = gql`
  query client($id: Int!) {
    client(id: $id) {
      id
      name
      firstName
      lastName
      email
      secondaryEmail
      otherEmail
      phone
      budget
      status
			currentStatus
      moveInDate
      nextFollowUp
      notes
      addonsArray
      availFrom
      availTo
      sqFeet
      bathroom
      unitFeaturesArray
      neighborhoodIdsArray
      neighborhoodArray
      minRent
      maxRent
      policiesArray
      bedroomArray
      nextFollowUp
      rating
      petNumber
      petType
			parkingPreferencesArray
			backgroundArray
			creditScore
      noOfParking
			leaseEndDate
			leaseTerm
      movingInspiration
      bio
      searchStatus
      currentResidence
      occupation
      income
      miscellaneous
      leadSource
      leadSourceDetails
      birthday
      createdAt
      updatedAt
      city {
        name
        id
      }
			roommates {
				id
				firstName
				lastName
				email
				phone
			}
      deal {
        property {
          id
          name
        }
        unitNumber
        mls
        leaseSigned
        leaseStartDate
        leaseEndDate
        leaseTerm
        grossRent
        concession
        isPercentBuildingCommission
        buildingCommission
        netRent
        invoiceTotal
        city {
          id
          name
        }
      }
    }
  }
`;

export const GUEST_REPORTS = gql`
  query reports {
    reports {
      edges {
        node {
          id
          name
          title
          reportCode
          createdAt
          updatedAt
          clientName
					isShow
          hidePropertyName
					client {
						name
						discardedAt
					}
        }
      }
      totalCount
    }
  }
`;

export const GUEST_HISTORY = gql`
  query guests {
    guests {
      edges {
        node {
          id
          name
          email
          phone
          moveIn
          budget
          appointmentType
          tourAt
          communities
        }
      }
      totalCount
    }
  }
`;

export const GUEST_COMUNITIES = gql`
  query communities($cityId: Int!) {
    communities(cityId: $cityId)
  }
`;

export const NOTIFICATION = gql`
  query notification($id: Int!) {
    notification(id: $id) {
      title
      description
			notificationType
    }
  }
`;

export const PROPERTIES_MARKERS = gql`
  query propertiesWithoutJoinData(
    $cityId: Int
    $minRent: Int
    $maxRent: Int
    $availFrom: ValidString
    $availTo: ValidString
    $yearFrom: ValidString
    $yearTo: ValidString
    $renovatedFrom: ValidString
    $neighborhoodIds: [Int!]
    $escort: ValidString
    $sendEscort: ValidString
    $zip: ValidString
    $sqFeet: ValidString
    $bedroom: [ValidString!]
    $bathroom: [ValidString!]
    $search: ValidString
    $addons: [ValidString!]
    $policies: [ValidString!]
    $unitFeatures: [ValidString!]
    $bonus: ValidString
    $rating: ValidString
    $zipCodes: [String!]
  ) {
    propertiesWithoutJoinData(
      #first: 25
      cityId: $cityId
      minRent: $minRent
      maxRent: $maxRent
      availFrom: $availFrom
      availTo: $availTo
      yearFrom: $yearFrom
      yearTo: $yearTo
      renovatedFrom: $renovatedFrom
      neighborhoodIds: $neighborhoodIds
      escort: $escort
      sendEscort: $sendEscort
      zip: $zip
      sqFeet: $sqFeet
      bedroom: $bedroom
      bathroom: $bathroom
      search: $search
      addons: $addons
      policies: $policies
      unitFeatures: $unitFeatures
      bonus: $bonus
      rating: $rating
      onlyAvailable: false
      zipCodes: $zipCodes
    ) {
      edges {
        node {
          id
          slug
          name
          email
          secondaryEmail
          otherEmail
          lat
          long
          #imageUrls
          city {
            name
            slug
          }
          neighborhood {
            name
            slug
          }
          floorPlans {
            #id
            #name
            bed
            bath
            #plan2d
            #unitFeatures
            rentMin
            rentMax
            #sqftMin
						isAvailable
          }
        }
      }
    }
  }
`;

export const ALL_PROPERTIES = gql`
  query allProperties {
    allProperties {
      edges {
        node {
          id
          slug
          name
          email
          secondaryEmail
          otherEmail
        }
      }
    }
  }
`;

export const PROPERTIES = gql`
  query properties(
    $cityId: Int
    $minRent: Int
    $maxRent: Int
    $availFrom: ValidString
    $availTo: ValidString
    $builtYear: ValidString
    $yearTo: ValidString
    $neighborhoodIds: [Int!]
    $escort: ValidString
    $sendEscort: ValidString
    $zip: ValidString
    $sqFeet: ValidString
    $bedroom: [ValidString!]
    $bathroom: [ValidString!]
    $search: ValidString
    $addons: [ValidString!]
    $policies: [ValidString!]
    $unitFeatures: [ValidString!]
    $bonus: ValidString
    $rating: ValidString
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: [OrderBy!]
    $phone: ValidString
    $email: ValidString
    $address: ValidString
    $renovated: ValidString
    $managementCompany: ValidString
    $emailFilter: ValidString
    $specialsAndCommissionPriority: ValidString
    $privateNote: ValidString
    $communicationMethod: ValidString
    $manualPricingFrequency: ValidString
    $photos: ValidString
  ) {
    properties(
      cityId: $cityId
      minRent: $minRent
      maxRent: $maxRent
      availFrom: $availFrom
      availTo: $availTo
      builtYear: $builtYear
      yearTo: $yearTo
      neighborhoodIds: $neighborhoodIds
      escort: $escort
      sendEscort: $sendEscort
      zip: $zip
      sqFeet: $sqFeet
      bedroom: $bedroom
      bathroom: $bathroom
      search: $search
      addons: $addons
      policies: $policies
      unitFeatures: $unitFeatures
      bonus: $bonus
      rating: $rating
      after: $after
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
      onlyAvailable: false
      phone: $phone
      email: $email
      address: $address
      renovated: $renovated
      managementCompany: $managementCompany
      emailFilter: $emailFilter
      specialsAndCommissionPriority: $specialsAndCommissionPriority
      privateNote: $privateNote
      communicationMethod: $communicationMethod
      manualPricingFrequency: $manualPricingFrequency
      photos: $photos
    ) {
      totalCount
      edges {
        node {
          city {
            id
            name
          }
          id
          slug
          name
          email
					secondaryEmail
					otherEmail
          address
          phone
          lat
          long
          units
          renovated
          zip
          builtYear
          managementCompany
          managementConfirmation
          googleMap
          googleRating
          googleReviewLink
          photoGalleryLink
          floorPlanLink
          neighborhood {
            id
            name
          }
          webLink
          specials
          escort
          sendEscort
          bonus
          commissionNote
          imageThumbUrls
          imageUrls
          imageIds
          addonItems
          policyItems
          updatedAt
          isConfirmed
          autoUpdate
          incomeRequirementLevel
          virtualTourLink
          additionalNote
          schedulingNote
          feesSheetUrl
					siteMapUrl
					virtualTours
					parkingNote
					privateNote
					invoicingNote
					specialsUpdatedAt
					unitsUpdatedAt
          specialsAndCommissionPriority
          manualPricingFrequency
          communicationMethod
          manualUpdate
          noCommission
          amenitiesConfirmed
          policyNote
          displayFloorPlanStatus
          floorPlans {
            id
            name
            bed
            bath
            price
            rentMin
            rentMax
            sqft
            sqftMax
            sqftMin
            description
            virtualTourLink
            plan2d
            plan3d
            imageUrls
            imageIds
            unitFeatures
            unitsAvailable
						availableFrom
						availableTo
            updatedAt
          }
          typeDetails {
            id
            floorPlan {
              id
              bed
              bath
              name
              unitFeatures
							availableFrom
							availableTo
							rentMin
							rentMax
							sqftMax
							sqftMin
            }
            moveIn
            price
            size
            aptNo
            isAvailable
            addonItems
            updatedAt
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const PROPERTY_LIST_VIEW = gql`
  query properties(
    $cityId: Int
    $minRent: Int
    $maxRent: Int
    $availFrom: ValidString
    $availTo: ValidString
    $yearFrom: ValidString
    $yearTo: ValidString
    $renovatedFrom: ValidString
    $neighborhoodIds: [Int!]
    $escort: ValidString
    $sendEscort: ValidString
    $zip: ValidString
    $sqFeet: ValidString
    $bedroom: [ValidString!]
    $bathroom: [ValidString!]
    $search: ValidString
    $addons: [ValidString!]
    $policies: [ValidString!]
    $unitFeatures: [ValidString!]
    $bonus: ValidString
    $rating: ValidString
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: [OrderBy!]
    $onlyAvailable: Boolean
    $phone: ValidString
    $propertyIds: [Int!]
    $zipCodes: [String!]
  ) {
    properties(
      #first: 10
      cityId: $cityId
      minRent: $minRent
      maxRent: $maxRent
      availFrom: $availFrom
      availTo: $availTo
      yearFrom: $yearFrom
      yearTo: $yearTo
      renovatedFrom: $renovatedFrom
      neighborhoodIds: $neighborhoodIds
      escort: $escort
      sendEscort: $sendEscort
      zip: $zip
      sqFeet: $sqFeet
      bedroom: $bedroom
      bathroom: $bathroom
      search: $search
      addons: $addons
      policies: $policies
      unitFeatures: $unitFeatures
      bonus: $bonus
      rating: $rating
      after: $after
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
      onlyAvailable: $onlyAvailable
      phone: $phone
			propertyIds: $propertyIds
      zipCodes: $zipCodes
    ) {
      totalCount
      edges {
        node {
          city {
            id
            name
            slug
          }
          id
          name
          slug
          email
					secondaryEmail
					otherEmail
          address
          phone
          units
          renovated
          builtYear
          googleMap
          googleRating
          imageThumbUrls
          imageUrls
          managementCompany
          managementConfirmation
          addonItems
          policyItems
          lat
          long
          googleReviewLink
          neighborhood {
            id
            name
            slug
          }
          webLink
          specials
          escort
          sendEscort
          commissionNote
          bonus
          updatedAt
          additionalNote
          schedulingNote
          virtualTourLink
          feesSheetUrl
          siteMapUrl
					specialsUpdatedAt
					unitsUpdatedAt
					virtualTours
					parkingNote
					invoicingNote
          specialsAndCommissionPriority
          manualPricingFrequency
          floorPlans {
            id
            name
            bed
            bath
            plan2d
            unitFeatures
						unitsAvailable
            rentMin
            rentMax
						sqft
            sqftMin
            sqftMax
            updatedAt
            isAvailable
						availableFrom
						availableTo
            units {
              id
              aptNo
              size
              price
              addonItems
              isAvailable
              moveIn
              updatedAt
            }
          }
          userPropertyColor{
            id
            color
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const PROPERTY = gql`
  query property(
    $id: ValidString!
    $minRent: Int
    $maxRent: Int
    $availFrom: ValidString
    $availTo: ValidString
    $sqFeet: ValidString
    $bedroom: [ValidString!]
    $bathroom: [ValidString!]
    $unitFeatures: [ValidString!]
  ) {
    property(
      id: $id
      minRent: $minRent
      maxRent: $maxRent
      availFrom: $availFrom
      availTo: $availTo
      sqFeet: $sqFeet
      bedroom: $bedroom
      bathroom: $bathroom
      unitFeatures: $unitFeatures
    ) {
      city {
        id
        name
        slug
      }
      id
      slug
      name
      email
			secondaryEmail
			otherEmail
      address
      phone
      lat
      long
      units
      renovated
      zip
      builtYear
      managementCompany
      managementConfirmation
      googleMap
      googleRating
      googleReviewLink
      photoGalleryLink
      floorPlanLink
      neighborhood {
        id
        name
        slug
      }
      webLink
      specials
      escort
      sendEscort
      bonus
      commissionNote
      imageThumbUrls
      imageUrls
      imageIds
      addonItems
      policyItems
      updatedAt
      isConfirmed
			autoUpdate
      incomeRequirementLevel
      virtualTourLink
      additionalNote
      schedulingNote
      specialsAndCommissionPriority
      manualPricingFrequency
      unitsUpdatedAt
      manualUpdate
      noCommission
      amenitiesConfirmed
      policyNote
      floorPlans {
        id
        name
        bed
        bath
        plan2d
        unitFeatures
				unitsAvailable
        rentMin
        rentMax
        sqftMin
        sqftMax
        isAvailable
				availableFrom
        units {
          id
          aptNo
          size
          price
          addonItems
          isAvailable
          moveIn
        }
      }
      userPropertyNote{
        id
        notes
      }
      typeDetails {
        id
        floorPlan {
          id
          bed
          bath
          name
          unitFeatures
        }
        moveIn
        price
        size
        aptNo
        isAvailable
        addonItems
        updatedAt
      }
    }
  }
`;

export const PROPERTY_BY_SLUG = gql`
  query propertyBySlug(
    $slug: ValidString!
  ) {
    propertyBySlug(
      slug: $slug
    ) {
      city {
        id
        name
        slug
      }
      id
      slug
      name
      email
      secondaryEmail
      otherEmail
      address
      phone
      lat
      long
      units
      renovated
      zip
      builtYear
      managementCompany
      managementConfirmation
      googleMap
      googleRating
      googleReviewLink
      photoGalleryLink
      floorPlanLink
      neighborhood {
        id
        name
        slug
      }
      webLink
      specials
      escort
      sendEscort
      bonus
      commissionNote
      imageThumbUrls
      imageUrls
      imageIds
      addonItems
      policyItems
      updatedAt
      isConfirmed
      autoUpdate
      incomeRequirementLevel
      virtualTourLink
      additionalNote
      schedulingNote
      specialsAndCommissionPriority
      manualPricingFrequency
      unitsUpdatedAt
      manualUpdate
      noCommission
      amenitiesConfirmed
      policyNote
      floorPlans {
        id
        name
        bed
        bath
        plan2d
        unitFeatures
        unitsAvailable
        rentMin
        rentMax
        sqftMin
        sqftMax
        isAvailable
        availableFrom
        units {
          id
          aptNo
          size
          price
          addonItems
          isAvailable
          moveIn
        }
      }
      typeDetails {
        id
        floorPlan {
          id
          bed
          bath
          name
          unitFeatures
        }
        moveIn
        price
        size
        aptNo
        isAvailable
        addonItems
        updatedAt
      }
    }
  }
`;

export const PROPERTY_ADDONS = gql`
  query propertyAddons {
    propertyAddons {
      name
      taggingsCount
    }
  }
`;

export const PROPERTY_POLICIES = gql`
  query propertyPolicies {
    propertyPolicies {
      name
      taggingsCount
    }
  }
`;

export const UNIT_ADDONS = gql`
  query unitAddons {
    unitAddons {
      name
      taggingsCount
    }
  }
`;

export const UNIT_FEATURES = gql`
  query unitFeatures {
    unitFeatures {
      name
      taggingsCount
    }
  }
`;

export const GET_REPORT = gql`
  query report($code: ValidString!) {
    report(reportCode: $code) {
      id
      reportCode
      title
      name
      message
      isShow
			clientName
      hidePropertyName
      user {
        name
				firstName
				lastName
				email
				phoneNo
				licenseNo
				brokerageName
				avatarUrl
				avatarThumbUrl
				logoUrl
				logoThumbUrl
				brokerageInfo
				brokerageInfoUrl
				street1
				street2
				cityName
				stateName
				zip
				isAgent
				urlName
				facebook
				instagram
				linkedin
				twitter
				calendly
				tiktok
				youtube
				pinterest
				whatsapp
				wechat
				googlePage
				personalWebsite
				aboutMe
      }
      properties {
        name
        address
        specials
        googleMap
        googleRating
        escort
        webLink
        imageUrls
        virtualTourLink
        imageSmallUrls
        photoGalleryLink
        lat
        long
        id
        floorPlanLink
        googleReviewLink
        builtYear
        addonItems
        feesSheetUrl
				siteMapUrl
        renovated
        neighborhood {
          name
        }
        city {
          name
        }
				floorPlans {
					id
					bed
					bath
					name
					rentMin
					rentMax
					sqftMin
					sqftMax
					isAvailable
				}
      }
      reportProperties {
        id
        clientNotes
        clientRating
        floorPlanId
        propertyId
        typeDetailId
        isSelectedByAgent
      }
    }
  }
`;

export const GET_REPORT_PROPERTY = gql`
	query findReportProperty($reportCode: ValidString!, $propertyId: Int!){
		findReportProperty(reportCode: $reportCode, propertyId: $propertyId) {
			id
			name
			floorPlans {
				id
				bath
				bed
				name
				rentMax
				rentMin
				isAvailable
				virtualTourLink
				plan2d
				sqft
				sqftMax
				sqftMin
				availableFrom
				units {
					isAvailable
					moveIn
					aptNo
					id
					price
					size
				}
			}
		}    
	}
`;

export const CLIENT_REPORTS = gql`
  query clientReports($clientId: Int!) {
    clientReports(clientId: $clientId) {
      id
      reportCode
			clientName
      title
      name
      message
      isShow
      hidePropertyName
      reportProperties {
        id
        clientNotes
        clientRating
        floorPlanId
        isSelectedByAgent
        floorPlan {
          name
          sqft
          bed
          bath
        }
        propertyId
        property {
          name
          slug
          phone
          managementCompany
          webLink
          city {
            name
            slug
          }
          neighborhood {
            name
            slug
          }
        }
        typeDetailId
        typeDetail {
          addonItems
          aptNo
          size
          moveIn
          aptNo
          id
          price
          size
        }
      }
    }
  }
`;

export const GET_CLIENT_DASHBOARD = gql`
  query report {
    clients {
      totalCount
    }
    reports {
      totalCount
    }
    guests {
      totalCount
    }
    subscriptions {
      edges {
        node {
          currentStartDatetime
          currentEndDatetime
        }
      }
    }
  }
`;

export const GET_ADMIN_DASHBOARD = gql`
  query report {
    properties {
      totalCount
    }
    users {
      totalCount
    }
    subscriptions {
      totalCount
    }
    leads {
      totalCount
    }
    contactinquiries {
      totalCount
    }
    notifications {
      totalCount
    }
  }
`;

export const PLAN = gql`
  query plan($id: Int!) {
    plan(id: $id) {
      id
      name
      interval
      amount
      isVisible
      canTrial
      stripePlanId
      maxCities
      stripePlan {
        id
        amount
        product {
          id
          name
        }
      }
    }
  }
`;

export const CITY = gql`
  query city($id: Int!) {
    city(id: $id) {
      id
      isVisible
      name
      neighborhoods {
        name
        id
      }
      state {
        id
        name
      }
			timeZone
    }
  }
`;

export const PLANS = gql`
  query plans {
    plans {
      id
      name
      stripePlanId
      amount
      interval
      maxCities
      stripePlan {
        id
        amount
        active
        interval
        product {
          id
          name
        }
				tiersMode
				tiers
      }
      isVisible
      canTrial
    }
  }
`;

export const TRIAL_PLANS = gql`
  query trialPlans {
    trialPlans {
      id
      name
      amount
      interval
      stripePlanId
      maxCities
    }
  }
`;

export const ALL_STRIPE_PLANS = gql`
  query stripePlans {
    stripePlans {
      id
      amount
      interval
      amountDecimal
      product {
        name
        active
        livemode
      }
			tiersMode
			tiers
    }
  }
`;

export const STATES = gql`
  query states {
    states {
      id
      name
    }
  }
`;

export const TOUR_REQUEST_HISTORY = gql`
  query tourRequests {
    tourRequests {
      edges {
        node {
          id
          client {
            name
            email
            phone
            budget
            moveInDate
            email
            phone
						availFrom
						availTo
						minRent
						maxRent
          }
          property {
            name
            webLink
          }
          appointmentType
          tourAt
					tourAtInLocalTime
					status
					sentAt
					confirmedAt
        }
      }
    }
  }
`;

export const CITY_PROPERTIES = gql`
  query cityProperties($cityId: Int!) {
    cityProperties(cityId: $cityId) {
      name
      id
      email
    }
  }
`;

export const INVOICES = gql`
  query invoices {
    invoices {
      edges {
        node {
          id
          invoiceNumber
          client {
            id
            name
          }
          leaseAmount
          commission
          leaseStartDate
          leaseTerm
          moveIn
          paidOn
          property {
            id
            name
            email
          }
          status
          submittedOn
          unitNumber
          concession
          isPercentCommission
          sendTo
        }
      }
    }
  }
`;

export const INVOICE = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      id
      invoiceNumber
      leaseAmount
      leaseStartDate
      leaseTerm
      commission
      moveIn
      paidOn
      status
      submittedOn
      unitNumber
      concession
      isPercentCommission
      sendTo
			totalInvoiceAmount
      createdAt
      mls
      leasingAgentName
      referralAgentName
      referralAgentEmail
      referralCommission
      isPercentReferralCommission
      checkNumber
      checkDate
      notes
      property {
        id
        name
        email
        city {
          id
          name
        }
      }
      client {
        id
        name
      }
			user{
				brokerageName
				name
				street1
				street2
				cityName
				stateName
				zip
				phoneNo
				email
			}
    }
  }
`;

export const INVOICE_NUMBER = gql`
  query nextInvoiceNumber {
    nextInvoiceNumber
  }
`;

export const FIND_TOUR_REQUEST = gql`
	query findTourRequest($id: Int!) {
		findTourRequest(id: $id) {
			appointmentType
			city {
				id
				name
				jsTimeZone
			}
			client {
				id
				name
			}
			property {
				name
				id
			}
			tourAt
			status
			sentAt
			confirmedAt
		}
	}
`;

export const ALL_AGENTS = gql`
	query allAgents{
		allAgents {
			edges {
				node {
					id
					name
					email
					phoneNo
					licenseNo
					approved
          brokerageName
				}
			}
		}
	}
`;

export const GET_BROKER = gql`
query getBroker($brokerCode: String){
  getBroker(brokerCode: $brokerCode){
		idCode
		id
		name
		brokerageName
		email
		phoneNo
		subscriptions {
			id
			cities{
				id
				name
			}
			active
			status
			plan {
				maxCities
			}
		}
	}
}`;

export const AGENT = gql`
	query agent($id: Int!){
		agent(id: $id) {
			id
			name
			email
			phoneNo
			street1
			street2
			cityName
			stateName
			zip
			licenseNo
			clientsCount
			sentListsCount
			guestCardsCount
			activeClientsCount
			authorizedCities{
				id
				name
			}
			brokerSubscriptions{
				plan{
					name
				}
			}
		}
	}
`;

export const BROKER = gql`
	query broker($id: Int){
		broker(id: $id) {
			idCode
			id
			name
      email
			phoneNo
			subscriptions {
				id
				active
				cities{
					id
					name
				}
				currentEndDatetime
				currentStartDatetime
				isTrial
				plan{
					id
					name
					maxCities
					amount
				}
				status
				stripeSubscriptionId
			}
		}
	}
`;

export const AGENT_CITY_ACCESS = gql`
	query agentCityAccess($agentId: Int!, $brokerId: Int, $subscriptionId: Int!){
		agentCityAccess(agentId: $agentId, brokerId: $brokerId, subscriptionId: $subscriptionId) {
			id
			name
		}
	}
`;

export const ALL_BROKERS = gql`
	query allBrokers{
		allBrokers {
			id
			name
			subscriptions {
				cities{
					id
					name
				}
				active
				plan{
					maxCities
				}
			}
		}
	}
`;

export const PUBLIC_USER = gql`
	query publicUser($urlName: String!){
		publicUser(urlName: $urlName) {
			aboutMe
			avatarThumbUrl
			avatarUrl
			brokerageInfo
			brokerageName
			calendly
			cityName
			createdAt
			email
			facebook
			firstName
			googlePage
			id
			instagram
			isAgent
			isBroker
			isUser
			isVa
			lastName
			licenseNo
			linkedin
			logoThumbUrl
			brokerageInfoUrl
			logoUrl
			name
			personalWebsite
			phoneNo
			pinterest
			signature
			stateName
			street1
			street2
			tiktok
			twitter
			updatedAt
			urlName
			wechat
			whatsapp
			youtube
			zip
		}
	}
`;

export const CLIENT_INQUIRIES = gql`
	query clientInquiries{
		clientInquiries {
			edges {
				node {
					addonsArray
					availFrom
					availTo
					backgroundArray
					bathroom
					bedroomArray
					budget
					city{
						id
						name
					}
					createdAt
					creditScore
					email
					firstName
					fromWhereYouKnowUs
					id
					lastName
					leaseEndDate
					leaseTerm
					maxRent
					minRent
					moveInDate
					name
					neighborhoodIdsArray
					neighborhoodArray
					noOfParking
					notes
					parkingPreferencesArray
					petNumber
					petTypeArray
					phone
					policiesArray
					rating
					secondaryEmail
					sqFeet
					unitFeaturesArray
					updatedAt
					yearFrom
				}
			}
		}
	}
`;

export const CLIENT_INQUIRY = gql`
	query clientInquiry($id: Int!){
		clientInquiry(id: $id) {
			addonsArray
			availFrom
			availTo
			backgroundArray
			bathroom
			bedroomArray
			budget
			city{
				id
				name
			}
			createdAt
			creditScore
			email
			firstName
			fromWhereYouKnowUs
			id
			lastName
			leaseEndDate
			leaseTerm
			maxRent
			minRent
			moveInDate
			name
			neighborhoodIdsArray
			neighborhoodArray
			noOfParking
			notes
			parkingPreferencesArray
			petNumber
			petTypeArray
			phone
			policiesArray
			rating
			secondaryEmail
			sqFeet
			unitFeaturesArray
			updatedAt
			yearFrom
		}
	}
`;

export const EMAIL_TEMPLATES = gql`
	query emailTemplates {
		emailTemplates {
			body
			ccTo
			createdAt
			id
			userId
			name
			sendTo
			subject
			updatedAt
		}
	}
`;

export const EMAIL_TEMPLATE_HISTORIES = gql`
  query emailTemplateHistories {
    emailTemplateHistories {
      edges {
        node {
          id
          userId
          emailTemplateId
          sendTo
          ccTo
          bccTo
          subject
          body
          createdAt
          updatedAt
          appointmentType
          tourAt
          client {
            name
          }
          property {
            name
          }
        }
      }
    }
  }
`;

export const EMAIL_TEMPLATE_HISTORY = gql`
query emailTemplateHistory($id: Int!) {
  emailTemplateHistory(id: $id) {
        id
        subject
        sendTo
        ccTo
        body
        bccTo         
        appointmentType
        tourAt
        userId
        user {
          name
          phoneNo
          email
        }
        emailTemplateId
        clientId
        client{
          name
          email
          phone
          bedroomArray
          bathroom
          moveInDate
          budget
          petNumber
          noOfParking
          notes     
        }
        propertyId
        property{
          name
          escort
          sendEscort
          bonus
        }
      }
    }
`;

export const USER_PROPERTY_NOTE = gql`
query userPropertyNote($propertyId: String!) {
  userPropertyNote(propertyId: $propertyId) {
    id
    notes
  }
}
`;
