import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Avatar from 'react-avatar';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupText,
  CardBody,
  Card,
  Label,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import Select from 'react-select';
import Spinner from '../../Spinner';
import { toast } from 'react-toastify';
import SidebarRight from '../../SidebarRight';
import Nouislider from 'nouislider-react';
import {
  CLIENTS,
  UPDATE_CLIENT_STATUS,
  UPDATE_CLIENT_NEXT_FOLLOW_UP,
  CLIENT_MULTI_DELETE,
  NEIGHBORHOODS,
	CLIENT_MULTI_DISCARD,
	CLIENT_MULTI_UNDISCARD,
  UPDATE_CLIENT_BUDGET,
  UPDATE_MOVE_IN_DATE,
  UPDATE_CLIENT_EMAIL,
  UPDATE_CLIENT_PHONE
} from '@api';
import { useQuery, useMutation } from '@apollo/client';
import ClientForm from './ClientForm';
import ClientShow from './ClientShow';
import ClientListTable from './ClientListTable';
import { Icon } from '@assets';
import { ToggleButton, ClientStatuses } from '@sparklib';
import moment from 'moment';
import KanbanView from './KanbanView';
import { enforceFormat, formatToPhone, justFomatePhoneNo } from '../../shared/sparkLib/phoneNumberFormatter';

export default function ClientList(props) {
  const [clients, setClients] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [allNeighborhoodsData, setAllNeighborhoodsData] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [clientsList, setClientsList] = useState('loading');
  const [showDiscarded, setShowDiscarded] = useState(false);
	const [showKanbanView, setShowKanbanView] = useState(false);
  const [budgetModal, setBudgetModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [minRent, setMinRent] = useState(null);
  const [maxRent, setMaxRent] = useState(null);
  const toggle = (id, minRent, maxRent) => 
    {
      setCurrentId(id);
      setMinRent(minRent);
      setMaxRent(maxRent);
      setBudgetModal(!budgetModal);
    }
  
  const [dateModal, setDateModal] = useState(false);
  const [availTo, setAvailTo] = useState(null);
  const [availFrom, setAvailFrom] = useState(null);
  const [moveInError, setMoveInError] = useState(false);
  const dateToggle = (id, availFrom, availTo) =>
    {
      setCurrentId(id);
      setAvailTo(availTo);
      setAvailFrom(availFrom);
      setDateModal(!dateModal);
    }

  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [otherEmail, setOtherEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const emailToggle = (id, email, secondaryEmail, otherEmail) => {
    setCurrentId(id);
    setEmail(email || ''); 
    setSecondaryEmail(secondaryEmail || ''); 
    setOtherEmail(otherEmail || '');
    setEmailModal(!emailModal);
  };
	
  const { data: neighborhoodData } = useQuery(NEIGHBORHOODS, {
    variables: { cityId: null },
    onCompleted: (data) => {},
  });

  const numberFormatter = new Intl.NumberFormat('en-US');

  useEffect(() => {
    neighborhoodData && setAllNeighborhoodsData(neighborhoodData.neighborhoods);
  }, [neighborhoodData]);

  const {
    data: clientData,
    loading: clientLoading,
    refetch: refetchClients,
  } = useQuery(CLIENTS, {
    skip: allNeighborhoodsData.length === 0,
    onCompleted: (data) => {},
    onError: (e) => {
      console.log(e);
      // toast(e, { type: "error" });
    },
  });

  const [clientMultiDelete] = useMutation(CLIENT_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.clientMultiDelete.message, { type: 'success' });
      setIsProgress(false);
    },
    refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e);
    },
  });

	const [clientMultiDiscard] = useMutation(CLIENT_MULTI_DISCARD, {
		onCompleted: (data) => {
      toast(data.clientMultiDiscard.message, { type: 'success' });
		},
		refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e);
    },
	});

	const [clientMultiUndiscard] = useMutation(CLIENT_MULTI_UNDISCARD, {
		onCompleted: (data) => {
      toast(data.clientMultiUndiscard.message, { type: 'success' });
		},
		refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e);
    },
	});

  const [updateClientEmail] = useMutation(UPDATE_CLIENT_EMAIL, {
    onCompleted: (data) => {
      toast(data.updateClientEmail.message, { type: 'success' });
      emailToggle();
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [updateClientPhone] = useMutation(UPDATE_CLIENT_PHONE, {
		onCompleted: (data) => {
      toast(data.updateClientPhone.message, { type: 'success' });
		},
		refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e);
    },
	});

  const [updateClientNextFollowUp] = useMutation(UPDATE_CLIENT_NEXT_FOLLOW_UP, {
		onCompleted: (data) => {
      toast(data.updateClientNextFollowUp.message, { type: 'success' });
		},
		refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e);
    },
	});

  const [updateClientBudget] = useMutation(UPDATE_CLIENT_BUDGET, {
		onCompleted: (data) => {
      toast(data.updateClientBudget.message, { type: 'success' });
      toggle();
		},
    onError: (e) => {
      console.log(e);
    },
	});

  const [updateMoveInDate] = useMutation(UPDATE_MOVE_IN_DATE, {
		onCompleted: (data) => {
      toast(data.updateMoveInDate.message, { type: 'success' });
      dateToggle();
		},
    onError: (e) => {
      console.log(e);
    },
	});

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const getNeighborhoodsArray = (neighborhoodIdsArray) => {
    let neighborhoodListItems = [];
    neighborhoodIdsArray?.map((id) => {
      let n = allNeighborhoodsData.find(
        (neighborhood) => Number(neighborhood.id) === Number(id)
      );
      if (n) {
        neighborhoodListItems.push({ label: n.name, value: n.id });
      }
    });
    return neighborhoodListItems;
  };

  const handleDeleteAll = async () => {
    setIsProgress(true);
    await clientMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const addNewClient = () => {
    setSidebarState(true);
  };

	function showClient(id){
		setSidebarState(true);
    history.push(`/clients/show/${id}`);
	}

  useEffect(() => {
		var filteredClients = [];
    if (clientData) {
      if (clientData.clients.totalCount === 0) {
        setClientsList('noData');
      } else {
				if (showDiscarded) {
					filteredClients = clientData.clients.edges.filter(c => c.node.discardedAt)
				} 
				else {
					filteredClients = clientData.clients.edges.filter(c => !c.node.discardedAt);
				}
				filteredClients = filteredClients.map(client => {
					return ({...client, allEmails: getAllEmails(client.node)})
				});
				setClients(filteredClients)
        setClientsList('data');
      }
    }
  }, [clientData, showDiscarded]);

	function getAllEmails(client){
		var emailString = ""
		if (client){
			if (client.email) emailString = emailString.concat(client.email)
			if (client.secondaryEmail) emailString = emailString.concat(" ", client.secondaryEmail)
			if (client.otherEmail) emailString = emailString.concat(" ", client.otherEmail)
		} 
		return emailString
	}

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    /* style: 'currency', */
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  function searchMatchingProperties(client) {
    history.push({
      pathname: '/properties',
      state: [
        {
          name: 'search',
          value: '',
        },
        {
          name: 'city',
          value: client.city.id
            ? `{"value":"${client.city.id}", "label":"${client.city.name}"}`
            : '{}',
        },
        {
          name: 'neighborhoods',
          value:
            client.neighborhoodIdsArray.length > 0
              ? getNeighborhoodsArray(client.neighborhoodIdsArray)
              : [],
        },
        {
          name: 'minPrice',
          value: client.minRent ? client.minRent : null,
        },
        {
          name: 'maxPrice',
          value: client.maxRent ? client.maxRent : '',
        },
        {
          name: 'bed',
          value:
            client.bedroomArray && client.bedroomArray.length > 0
              ? client.bedroomArray
              : [],
        },
        {
          name: 'bath',
          value: client.bathroom
            ? `{"value":"${client.bathroom}", "label":"${client.bathroom}"}`
            : '{}',
        },
        {
          name: 'sqFt',
          label: 'Size',
          value: client.sqFeet ? client.sqFeet.toString() : '',
        },
        {
          name: 'unitFeatures',
          label: 'Unit Features',
          value:
            client.unitFeaturesArray.length > 0
              ? client.unitFeaturesArray.map((opt) =>
                  JSON.parse(`{"value":"${opt}", "label":"${opt}"}`)
                )
              : [],
        },
        {
          name: 'availFrom',
          label: 'Available From',
          value: client.availFrom ? new Date(client.availFrom) : null,
        },
        {
          name: 'yearFrom',
          label: 'Year Built From',
          value: null,
        },
        {
          name: 'yearTo',
          label: 'Year Built To',
          value: null,
        },
        {
          name: 'availTo',
          label: 'Available To',
          value: client.availTo ? new Date(client.availTo) : null,
        },
        {
          name: 'bonus',
          label: 'Bonus',
          value: null,
        },
        {
          name: 'minSend',
          label: 'Send Escort',
          value: null,
        },
        {
          name: 'escort',
          label: 'Escort',
          value: null,
        },
        {
          name: 'amenities',
          label: 'Amenities',
          value:
            client.addonsArray.length > 0
              ? client.addonsArray.map((opt) =>
                  JSON.parse(`{"value":"${opt}", "label":"${opt}"}`)
                )
              : [],
        },
        {
          name: 'policies',
          label: 'Policies',
          value:
            client.policiesArray.length > 0
              ? client.policiesArray.map((opt) =>
                  JSON.parse(`{"value":"${opt}", "label":"${opt}"}`)
                )
              : [],
        },
        {
          name: 'googleRating',
          label: 'Google Rating',
          value: client.rating ? client.rating : '',
        },
      ],
    });
  }

  const history = useHistory();
  const [clientUpdate] = useMutation(UPDATE_CLIENT_STATUS, {
    onCompleted: (data) => {
      if (data.updateClientStatus.errors && data.updateClientStatus.errors.length > 0) {
        data.updateClientStatus.errors.map((err) => toast(err, { type: 'error' }));
      }
    },
    refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const _changeStatus = (id, value) => {
    clientUpdate({
      variables: {
        id: parseInt(id),
        currentStatus: value,
      },
    });
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

	const handleDiscard = (ids) => {
		if (Array.isArray(ids)) ids.map(id => parseInt(id));

		clientMultiDiscard({
			variables: {
				ids: Array.isArray(ids) ? ids : [parseInt(ids)]
			}
		})
	};

	const handleUndiscard = (ids) => {
		if (Array.isArray(ids)) ids.map(id => parseInt(id));
		clientMultiUndiscard({
			variables: {
				ids: Array.isArray(ids) ? ids : [parseInt(ids)]
			}
		})
	};

  const handleUpdateEmail = () => {
    if (!email) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    updateClientEmail({
      variables: {
        id: parseInt(currentId),
        email: email,
        secondaryEmail: secondaryEmail,
        otherEmail: otherEmail
      }
    });
  };

  const handleUpdatePhone = (phone, clientId) => {
		updateClientPhone({
      variables: {
        id: parseInt(clientId),
        phone: phone
      }
    });
	};

  const handleNextFollowUp = (nextFollowUpDate, clientId) => {
		updateClientNextFollowUp({
			variables: {
				id: parseInt(clientId),
        nextFollowUp: nextFollowUpDate
			}
		})
	};

  const handleClientBudget = () => {
    updateClientBudget({
			variables: {
				id: parseInt(currentId),
        minRent: parseInt(minRent),
        maxRent: parseInt(maxRent)
			}
		})
	};

  const handleMoveInDate = () => {
    if (!availFrom || !availTo) {
      setMoveInError(true); 
      return; 
    }
  
    setMoveInError(false);

		updateMoveInDate({
			variables: {
				id: parseInt(currentId),
        availTo: availTo,
        availFrom: availFrom
			}
		})
	};

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'node.name',
        Cell: ({ value, row }) => (
          <div>
            {value ? value : '-'}
          </div>
        ),
      },
      {
        Header: 'Next Follow Up',
        accessor: 'node.nextFollowUp',
        Cell: ({ value, row }) => (
          <div onClick={(e) => {
            e.stopPropagation();
          }}>
            <DatePicker
            selected={value ? new Date(value) : null}
            onChange={(date) => handleNextFollowUp(date, row.original.node.id)}
            dateFormat="MM/dd/yy"
            className={`form-control border-0 ${value && moment(value).isBefore() ? 'text-danger' : ''}`}
            />
          </div>
        ),
      },
      {
        Header: 'Market',
        accessor: 'node.city.name',
      },
      {
        Header: 'Email',
        accessor: 'allEmails',
        width: 200,
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <div onClick={() => emailToggle(row.original.node.id, row.original.node.email, row.original.node.secondaryEmail, row.original.node.otherEmail)}>
              {value ? (
                <div>{value}</div>
              ) : (
                '-'
              )}
            </div>
          </div>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'node.phone',
        width: 180,
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <input
              type="text"
              className="form-control border-0 client_phone"
              defaultValue={value ? justFomatePhoneNo(value) : ''}
              onKeyDown={(e) => enforceFormat(e)}
              onKeyUp={(e) => formatToPhone(e)}
              onChange={(e) => {
                if (e.target.value?.length <= 16) {
                  var formatedPhoneNo = justFomatePhoneNo(e.target.value);
                  if (formatedPhoneNo?.length === 16) {
                    handleUpdatePhone(formatedPhoneNo, row.original.node.id);
                  }
                }
              }}
            />
          </div>
        )
      },
      {
        Header: 'Budget',
        accessor: 'node.minRent',
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            {
              <div onClick={() => toggle(row.original.node.id, value, row.original.node.maxRent)}>
                {value && row.original.node.maxRent ? (
                  <div>
                    {value && '$' + currencyFormatter.format(value)} -{' '}
                    {'$' + currencyFormatter.format(row.original.node.maxRent)}{' '}
                  </div>
                ) : value ? (
                  <>
                    {'$' + currencyFormatter.format(value) + ' & Above'}
                  </>
                ) : row.original.node.maxRent ? (
                  <>
                    {'Below ' +
                      '$' +
                      currencyFormatter.format(row.original.node.maxRent)}
                  </>
                ) : (
                  '-'
                )}
              </div>
            }
          </div>
        ),
      },
      {
        Header: 'M/I Date',
        accessor: 'node.availFrom',
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <div onClick={() => dateToggle(row.original.node.id, value, row.original.node.availTo)}>
            {value ? (
              <div>
								{
									value && !row.original.node.availTo ? `After ${moment(value).format('MM/DD/YY')}` :
									!value && row.original.node.availTo ? `Before ${moment(row.original.node.availTo).format('MM/DD/YY')}` :
									`${moment(value).format('MM/DD/YY')} - ${moment(row.original.node.availTo).format('MM/DD/YY')}`
								}
              </div>
            ) : (
              '-'
            )}
            </div>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'node.currentStatus',
        width: 200,
        Cell: ({ value, row }) => (
          <div onClick={(e) => {
            e.stopPropagation();
          }}>
          <Select
            name='status'
            options={ClientStatuses}
            className='w-100'
            classNamePrefix='default-select'
            isSearchable={true}
            value={ClientStatuses.find(option => option.value === value)}
            onChange={(e) => _changeStatus(row.original.node.id, e.value)}
            placeholder={'Select status'}
          />
          </div>
        ),
      },
      // {
      //   Header: 'Action',
      //   accessor: 'node.id',
      //   disableSortBy: true,
      //   Cell: ({ value, row }) => (
      //     <div onClick={(e) => e.stopPropagation()}>
      //       <UncontrolledButtonDropdown>
      //         <DropdownToggle caret>
      //           <Icon icon='bars' />
      //         </DropdownToggle>
      //         <DropdownMenu>
      //           <DropdownItem
      //             onClick={() => {
      //               searchMatchingProperties(row.original.node);
      //             }}
      //           >
      //             View Matching Properties
      //           </DropdownItem>
      //           <DropdownItem divider />
			// 					<DropdownItem
			// 						onClick={() => showClient(value)}
			// 					>
      //             View Details
      //           </DropdownItem>
      //           <DropdownItem divider />
      //           {/* <DropdownItem
      //             onClick={() => {
      //               setSidebarState(true);
      //               history.push(`/clients/edit/${value}`);
      //             }}
      //           >
      //             Edit
      //           </DropdownItem>
      //           <DropdownItem divider /> */}
			// 					{
			// 						row.original.node.discardedAt ? 
			// 						<DropdownItem onClick={() => handleUndiscard(value)}>
			// 							Unarchive
			// 						</DropdownItem> :
			// 						<DropdownItem onClick={() => handleDiscard(value)}>
			// 							Archive
			// 						</DropdownItem>
			// 					}
      //           <DropdownItem divider />
      //           <DropdownItem onClick={() => handleSingleDelete(value)}>
      //             Delete
      //           </DropdownItem>
      //         </DropdownMenu>
      //       </UncontrolledButtonDropdown>
      //     </div>
      //   ),
      // },
    ],
    [allNeighborhoodsData]
  );

  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);

  useEffect(() => {
    // check !id and operation is edit
    sidebarState
      ? !id && history.push(`/clients/new`)
      : history.push(`/clients`);
  }, [sidebarState, operation]);
  
  return (
    <>
      {clientLoading && <Spinner />}
      {operation === 'edit' ? (
        <SidebarRight
          sidebarState={sidebarState}
          sidebarToggle={() => {
            setSidebarState(!sidebarState);
          }}
          sidebarWidth='1150px'
          sidebarContent={
            <ClientForm
              new={operation}
              sidebarToggle={() => {
                setSidebarState(!sidebarState);
              }}
							refetchClients={refetchClients}
              {...props}
            />
          }
        />
      ) : operation === 'new' ? (
        <SidebarRight
          sidebarState={sidebarState}
          sidebarToggle={() => {
            setSidebarState(!sidebarState);
          }}
          sidebarWidth='1150px'
          sidebarContent={
            <ClientForm
              new={operation}
              sidebarToggle={() => {
                setSidebarState(!sidebarState);
              }}
							searchMatchingProperties={searchMatchingProperties}
							refetchClients={refetchClients}
              {...props}
            />
          }
        />
      ) : (
			<SidebarRight
				sidebarState={sidebarState}
				sidebarToggle={() => {
					setSidebarState(!sidebarState);
				}}
				searchMatchingProperties={searchMatchingProperties}
				sidebarWidth='1150px'
				sidebarContent={
					// <ClientShow
					// 	new={operation}
					// 	searchMatchingProperties={searchMatchingProperties}
					// 	sidebarToggle={() => {
					// 		setSidebarState(!sidebarState);
					// 	}}
					// 	{...props}
					// />
					<ClientForm
						new={operation}
						sidebarToggle={() => {
							setSidebarState(!sidebarState);
						}}
						searchMatchingProperties={searchMatchingProperties}
						refetchClients={refetchClients}
						{...props}
					/>
				}
			/>
			)
		}
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} client
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={emailModal} toggle={emailToggle} modalClassName='mt-5'>
        <ModalHeader toggle={emailToggle}>Update Emails</ModalHeader>
        <ModalBody>
          <Row>
            <Col md='12'>
              <Label for='email'>Primary Email</Label>
              <Input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Primary Email'
              />
              {emailError && !email && (
                <p className='input-error'>Primary email is required.</p>
              )}
            </Col>
            <Col md='12' className='mt-3'>
              <Label for='secondaryEmail'>Secondary Email</Label>
              <Input
                type='email'
                value={secondaryEmail}
                onChange={(e) => setSecondaryEmail(e.target.value)}
                placeholder='Secondary Email'
              />
            </Col>
            <Col md='12' className='mt-3'>
              <Label for='otherEmail'>Other Email</Label>
              <Input
                type='email'
                value={otherEmail}
                onChange={(e) => setOtherEmail(e.target.value)}
                placeholder='Other Email'
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateEmail}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={emailToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={budgetModal} toggle={toggle} modalClassName='mt-5'>                                                   
        <ModalHeader toggle={toggle}>Price</ModalHeader>
        <ModalBody>
          <Card className='mt-2 text-left rounded-small'>
            <CardBody className='p-3'>
              {/* <CardTitle>Card title</CardTitle> */}
              <label htmlFor=''>Price</label>
              <div className='px-2 py-2 mt-2 mb-1 w-100'>
              <Nouislider
                connect={true}
                tooltips={true}
                animate={true}
                start={[
                  minRent || 0,
                  maxRent || 10000,
                ]}
                behaviour='tap'
                range={{
                  min: [0, 100],
                  '5%': [100, 100],
                  '50%': [2600, 200],
                  '75%': [5000, 500],
                  max: [10000],
                }}
                onChange={(values) => {
                  const formattedMinRent = values[0] < 100 ? '' : values[0];
                  const formattedMaxRent = values[1] > 9500 ? '' : values[1];
    
                  setMinRent(formattedMinRent);
                  setMaxRent(formattedMaxRent);
                }}
                format={{
                  to: function (value) {
                    return value === undefined
                      ? ''
                      : value.toFixed(0);
                  },
                  from: Number,
                }}
              />
              </div>
              <Row className='mt-2'>
                <Col md='6'>
                  <InputGroup className='mb-3'>
                    <InputGroupText>
                      <Icon icon='dollar-sign' />
                    </InputGroupText>
                    <Input
                      type='text'
                      placeholder='Min Price'
                      min='0'
                      name='minRent'
                      value={minRent ? numberFormatter.format(minRent) : ''}
                      onChange={(e) => setMinRent(e.target.value?.replace(/,/g, ''))}
                    />
                  </InputGroup>
                </Col>
                <Col md='6'>
                  <InputGroup>
                    <InputGroupText>
                      <Icon icon='dollar-sign' />
                    </InputGroupText>
                    <Input
                      type='text'
                      min='0'
                      placeholder='Max Price'
                      name='maxRent'
                      value={maxRent ? numberFormatter.format(maxRent) : ''}
                      onChange={(e) => setMaxRent(e.target.value?.replace(/,/g, ''))}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClientBudget}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      
      <Modal isOpen={dateModal} toggle={dateToggle} modalClassName='mt-5'>                                                   
        <ModalHeader toggle={dateToggle}>Move In Date</ModalHeader>
        <ModalBody>
          <Row>
            <Col md='6'>
              <Label for='availFrom'>Earliest Move In</Label>
              <DatePicker
                className='form-control'
                selected={availFrom ? new Date(availFrom) : null}
                onChange={date => setAvailFrom(date)}
                placeholderText='Earliest Move In'
                dateFormat='MMMM d, yyyy'
                name='availFrom'
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {moveInError && !availFrom &&(
                <p className='input-error'>Provide both earliest and latest date.</p>
              )}
            </Col>
            <Col md='6'>
              <Label for='availTo'>Latest Move In</Label>
              <DatePicker
                className='form-control'
                selected={availTo ? new Date(availTo) : null}
                onChange={date => setAvailTo(date)}
                placeholderText='Latest Move In'
                dateFormat='MMMM d, yyyy'
                name='availTo'
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {moveInError && !availTo && (
                <p className='input-error'>Provide both earliest and latest date.</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleMoveInDate}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={dateToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Row >
        {/* <Col md='12' className='mt-md-4'> */}
        <Col md='12' className='mt-2'>
					{clientsList === 'data' && 
					<div className='mb-1 kanban-toggle-button'>
						<ToggleButton
							selected={showKanbanView}
							firstOption='Kanban View'
							secondOption='Table View'
							width='150px'
							rounded
							toggleSelected={() => {
								setShowKanbanView(!showKanbanView);
							}}
						/>
					</div>}
					<Col>
						{clients.length > 0 ? (
							showKanbanView ? 
							<KanbanView
								clients={clients} 
								updateClientStatus={_changeStatus} 
								showClient={showClient}
                handleSingleDelete={handleSingleDelete}
							/>
							:
							<ClientListTable
								columns={columns}
								data={clients}
								filterable
								addons={
									<>
										<Button
											color='red'
											onClick={onAllDeletePopup}
											disabled={isCheckedId.length > 0 ? false : true}
											className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
										>
											<Icon icon='trash-alt' className='mr-2' />
											Delete
										</Button>
										{	showDiscarded ? 
											<Button
												color='red'
												onClick={() => handleUndiscard(isCheckedId)}
												disabled={isCheckedId.length > 0 ? false : true}
												className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
											>
												<Icon icon='undo' className='mr-2' />
												Unarchive
											</Button> :
											<Button
												color='red'
												onClick={() => handleDiscard(isCheckedId)}
												disabled={isCheckedId.length > 0 ? false : true}
												className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
											>
												<Icon icon='archive' className='mr-2' />
												Archive
											</Button>
										}

									</>
								}
								leftAddons={
									<div className='properties-toggle-switch ml-3 inline-block'>
										<ToggleButton
											selected={showDiscarded}
											firstOption='Archived'
											secondOption='Active'
											width='120px'
											rounded
											toggleSelected={() => {
												setShowDiscarded(!showDiscarded);
											}}
										/>
									</div>
								}
								changeIsChecked={(isChecked) => setIsChecked(isChecked)}
								setSidebarState={setSidebarState}
							/>
						) : (
							<>
								{clientsList === 'noData' ? (
									<div className='text-center' style={{ margin: '35vh 0' }}>
										<h4>Oh... looks like you haven't created any clients yet!</h4>
										<Button
											onClick={() => addNewClient()}
											color='success'
											className='button-dashboard mt-2'
										>
											Create my first client{' '}
											<Icon icon='chevron-right' className='ml-2' />
										</Button>
									</div>
								) : null}
							</>
						)}
					</Col>
        </Col>
      </Row>
    </>
  );
}
