import React, { useState, useEffect, useMemo } from 'react';
import { useDebounce } from './useDebounce';
//import { useInterval } from './useInterval';
import { Prompt } from 'react-router';
import { ToggleButton, lastUpdatedOn } from '@sparklib';
//import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import {
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { toast } from 'react-toastify';

import { Icon } from '@assets';

import DataGrid, {
  // Row as PropertyRow,
  SelectColumn,
  // TextEditor,
  // ImageFormatter,
} from 'react-data-grid';

import { TextEditor, DateEditor } from './gridEditors';
import moment from 'moment';
import {
  ALL_CITIES,
  PROPERTIES,
  PROPERTY_ADDONS,
  PROPERTY_POLICIES,
  PROPERTY_CREATE,
  PROPERTY_UPDATE,
  PROPERTY_MULTI_UPDATE,
  PROPERTY_MULTI_DELETE,
  NEIGHBORHOODS,
  DELETE_PROPERTY_TYPE_DETAILS,
} from '@api';
import {
  useQuery,
  useMutation,
  useLazyQuery,
  refetchQueries,
} from '@apollo/client';
import _ from 'lodash';

import FilterRenderer from './FilterRenderer';
import PropertyPhotoEditor from './PropertyPhotoEditor';
import PropertyUnitsManager from './PropertyUnitsManager';
import {
  FilterContext,
  inputStopPropagation,
  selectStopPropagation,
  rowKeyGetter,
  getComparator,
} from './gridHelpers';
import { CurrencyFormatter } from './gridFormatters';
import FeesSheetEditor from './FeesSheetEditor';
import SiteMapEditor from './SiteMapEditor';
// function DropDownEditor({ options, row, onRowChange }) {
//   return (
//     <select
//       // className={textEditorClassname}
//       value={row?.neighborhood}
//       onChange={(event) => onRowChange({ ...row, neighborhood: event.target.value }, true)}
//       autoFocus
//     >
//       {options.map((option) => (
//         <option key={option} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }

export default function PropertyGrid(props) {
  const PAGE_SIZE = 20;
  const [properties, setProperties] = useState([]);
  const [propertiesCopy, setPropertiesCopy] = useState([]);

  const [pageInfo, setPageInfo] = useState({});
  const [loadProperties, setLoadProperties] = useState(true);

  const [addingNew, setAddingNew] = useState(false);
  const [propertyChanges, setPropertyChanges] = useState([]);
  const [isBulkProgress, setIsBulkProgress] = useState(false);
  const [propertyCount, setPropertyCount] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const [dataGridStatus, setDataGridStatus] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [showFeesSheetEditor, setShowFeesSheetEditor] = useState({show: false, property: null});
	const [showSiteMapEditor, setShowSiteMapEditor] = useState({show: false, property: null});
  const [showPhotoEditor, setShowPhotoEditor] = useState({
    show: false,
    property: null,
  });

  const [showUnitsManager, setShowUnitsManager] = useState({
    show: false,
    property: null,
  });

  const [sortColumns, setSortColumns] = useState([]);
  const [resetProperties, setResetProperties] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState(() => new Set());
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([{}]);
  const [addons, setAddons] = useState([{}]);
  const [policies, setPolicies] = useState([]);
  // const fullscreen = useFullScreenHandle();

  const noFilters = {
    name: '',
    isConfirmed: 'All',
    autoUpdate: 'All',
    unitsCount: 'All',
    photos: 'All',
    city: 'All',
    neighborhood: 'All',
    phone: '',
    emailFilter: 'All',
    googleRating: '',
    address: '',
    zip: '',
    units: '',
    escort: '',
    sendEscort: '',
    bonus: '',
    incomeRequirementLevel: '',
    builtYear: '',
    renovated: '',
    specials: '',
    managementCompany: '',
    addons: [],
    policies: [],
    enabled: true,
		commissionNote: '',
		schedulingNote: '',
		additionalNote: '',
		virtualTourLink: '',
		privateNote: '',
		parkingNote: '',
		invoicingNote: '',
		virtualTours: '',
    specialsAndCommissionPriority: 'All',
    manualPricingFrequency: 'All',
    communicationMethod: 'All',
    manualUpdate: 'All',
    noCommission: 'All',
    amenitiesConfirmed: 'All',
    policyNote: ''
  };

	useEffect(() => {
		if (localStorage.getItem('user_type') === 'admin') {
			setIsAdmin(true)
		}
	}, [])
  // const [filters, setFilters] = useState(noFilters);
  const [filters, setFilters, { signal, debouncing }] = useDebounce(noFilters);

  const { data: citiesData } = useQuery(ALL_CITIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      // propertyQuery();
    },
  });

  const { data: neighborhoodsData } = useQuery(NEIGHBORHOODS, {
    variables: { cityId: null },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      console.log(data, '--- neighborhoods');
      setNeighborhoods(data.neighborhoods);
    },
  });

  const { data: addonsData } = useQuery(PROPERTY_ADDONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      console.log(data, '--- addons');
      setAddons(data.propertyAddons);
    },
  });

  const { data: policiesData } = useQuery(PROPERTY_POLICIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      console.log(data, '--- policies');
      setPolicies(data.propertyPolicies);
    },
  });

  const filterAndSortVariables = () => {
    return {
      search: filters.name ? filters.name : null,
      cityId: Number(filters.city) > 0 ? Number(filters.city) : null,
      neighborhoodIds:
        Number(filters.neighborhood) > 0
          ? [Number(filters.neighborhood)]
          : null,
      addons: filters.addons.length > 0 ? filters.addons : null,
      policies: filters.policies.length > 0 ? filters.policies : null,
      escort: filters.escort ? filters.escort : null,
      sendEscort: filters.sendEscort ? filters.sendEscort : null,
      bonus: filters.bonus ? filters.bonus : null,
      phone: filters.phone.length > 0 ? filters.phone : null,
      emailFilter: filters.emailFilter ? filters.emailFilter : null,
      zip: filters.zip.length > 0 ? filters.zip : null,
			// builtYear: filters.builtYear ? filters.builtYear : null,
			// renovated: filters.renovated ? filters.renovated : null
			managementCompany: filters.managementCompany.length > 0  ? filters.managementCompany : null,
			rating: filters.googleRating.length > 0 ? filters.googleRating : null,
			address: filters.address.length > 0 ? filters.address : null,	
			builtYear: filters.builtYear.length === 4 ? filters.builtYear : null,	
			renovated: filters.renovated.length === 4 ? filters.renovated : null,
      specialsAndCommissionPriority: filters.specialsAndCommissionPriority ? filters.specialsAndCommissionPriority : null,
      manualPricingFrequency: filters.manualPricingFrequency ? filters.manualPricingFrequency : null,
      privateNote: filters.privateNote ? filters.privateNote : null,
      communicationMethod: filters.communicationMethod ? filters.communicationMethod : null,
      photos: filters.photos ? filters.photos : null,
    };
    // search: getFilter('search') ? getFilter('search') : null,
    // minRent: getFilter('minPrice') ? parseInt(getFilter('minPrice')) : null,
    // maxRent: getFilter('maxPrice') ? parseInt(getFilter('maxPrice')) : null,
    // availFrom: getFilter('availFrom') ? getFilter('availFrom') : null,
    // availTo: getFilter('availTo') ? getFilter('availTo') : null,
    // yearFrom: getFilter('yearFrom') ? getFilter('yearFrom').toString() : null,
    // yearTo: getFilter('yearTo') ? getFilter('yearTo') : null,
    // sqFeet: getFilter('sqFt') ? getFilter('sqFt') : null,
    // escort: getFilter('escort') ? getFilter('escort') : null,
    // sendEscort: getFilter('minSend') ? getFilter('minSend') : null,
    // // zip: getFilter('zip') ? getFilter('zip') : null,
    // bonus: getFilter('bonus') ? getFilter('bonus').toString() : null,
    // bedroom: getFilter('bed').length > 0 ? getFilter('bed') : null,
    // bathroom: JSON.parse(getFilter('bath'))?.value
    //   ? JSON.parse(getFilter('bath')).value
    //   : null, //getFilter('bath').length > 0 ? getFilter('bath') : null,

    // // pet: filters.selectedPet ? filters.selectedPet : null,
    // neighborhoodIds:
    //   getFilter('neighborhoods').length > 0
    //     ? getFilter('neighborhoods').map((opt) => Number(opt.value))
    //     : null,

    // addons:
    //   getFilter('amenities').length > 0
    //     ? getFilter('amenities').map((opt) => opt.label)
    //     : null,
    // policies:
    //   getFilter('policies').length > 0
    //     ? getFilter('policies').map((opt) => opt.label)
    //     : null,
    // unitFeatures:
    //   getFilter('unitFeatures').length > 0
    //     ? getFilter('unitFeatures').map((opt) => opt.label)
    //     : null,
    // rating: getFilter('googleRating')
    //   ? getFilter('googleRating').toString()
    //   : null,
    // orderBy: orderBy,
  };

  function reloadProperties() {
    setPageInfo({});
    // setProperties([]);
    setLoadProperties(true);
  }

  useEffect(() => {
    reloadProperties();
  }, [filters]);
  // const [propertyQuery, { data: propertiesData, loading: propertyLoading }] =
  const { data: propertiesData, loading: propertyLoading } = useQuery(
    PROPERTIES,
    {
      variables:
        loadProperties && pageInfo.hasNextPage
          ? {
              first: PAGE_SIZE,
              after: pageInfo.endCursor,
              ...filterAndSortVariables(),
            }
          : { first: PAGE_SIZE, ...filterAndSortVariables() },
      skip: !citiesData || !loadProperties,
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setLoadProperties(false);
        if (pageInfo?.endCursor) {
          setProperties((properties) => [
            ...properties,
            ...sanitizePropertiesData(propertiesData),
          ]);
          setPropertiesCopy((properties) => [
            ...properties,
            ...sanitizePropertiesData(propertiesData),
          ]);
        } else {
          setProperties(sanitizePropertiesData(propertiesData));
          setPropertiesCopy(sanitizePropertiesData(propertiesData));
        }

        setPropertyCount(propertiesData.properties.totalCount);
        setPageInfo(data.properties.pageInfo);
        console.log(data.properties.pageInfo, 'data.properties.pageInfo');
        console.log(data, '--- Properties');
      },
      onError: (e) => {
        toast(e, { type: 'error' });
        console.log(e, 'error');
      },
    }
  );

  const [propertyCreate] = useMutation(PROPERTY_CREATE, {
    onCompleted: (data) => {
      // setShowDeleteConfirmation(false);
      toast(data.propertyCreate.message, { type: 'success' });
      // setProperties(
      //   _.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id)
      // );
      setIsProgress(false);
      reloadProperties();
    },
    // optimisticResponse: {
    //   propertyCreate: {
    //           id: '-100',
    //           __typename: "Property",
    //           content: commentContent
    //         }
    //       },
    refetchQueries: [
      NEIGHBORHOODS,
      PROPERTIES,
      PROPERTY_ADDONS,
      PROPERTY_POLICIES,
    ],
    onError: (e) => {
      // setShowDeleteConfirmation(false);
      toast('Property not saved', { type: 'error' });
      console.log(e);
    },
  });

  const [propertyUpdate] = useMutation(PROPERTY_UPDATE, {
    onCompleted: (data) => {
      toast(data.propertyUpdate.message, { type: 'success' });
      // setProperties(
      //   _.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id)
      // );
      setIsProgress(false);
    },
    refetchQueries: [PROPERTIES, PROPERTY_ADDONS, PROPERTY_POLICIES],
    onError: (e) => {
      toast(e, { type: 'error' });
      console.log(e);
    },
  });

  const [propertiesMultiUpdate] = useMutation(PROPERTY_MULTI_UPDATE, {
    onCompleted: (data) => {
      toast(data.propertiesMultiUpdate.message, { type: 'success' });
      setIsProgress(false);
      // clear the changes
      setPropertyChanges([]);
      // propertyQuery();
      reloadProperties();
    },
    onError: (e) => {
      toast(e, { type: 'error' });
      console.log(e);
      setIsProgress(false);
    },
    refetchQueries: [
      NEIGHBORHOODS,
      PROPERTIES,
      PROPERTY_ADDONS,
      PROPERTY_POLICIES,
    ],
    // update: (cache, data) => {
    //   // console.log(data.data.propertiesMultiUpdate.properties, '---- updated Properties');
    //   const updatedProperties = data.data.propertiesMultiUpdate.properties;
    //   const { properties } = cache.readQuery({ query: PROPERTIES });
    //   let updatedProperty;
    //   const mergedProperties = properties.edges.map((property) => {
    //     updatedProperty = updatedProperties.find(
    //       (p) => p.id === property.node.id
    //     );
    //     return updatedProperty
    //       ? { ...property, node: updatedProperty }
    //       : property;
    //   });
    //   const updatedPropertiesConnection = {
    //     ...properties,
    //     edges: mergedProperties,
    //   };
    //   cache.writeQuery({
    //     query: PROPERTIES,
    //     data: { properties: updatedPropertiesConnection },
    //   });
    // },
  });

  const [propertyMultiDelete] = useMutation(PROPERTY_MULTI_DELETE, {
    onCompleted: (data) => {
      setShowDeleteConfirmation(false);
      toast(data.propertyMultiDelete.message, { type: 'success' });
      setProperties(
        _.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id)
      );
      setIsProgress(false);
      reloadProperties();
    },
    refetchQueries: [
      NEIGHBORHOODS,
      PROPERTIES,
      PROPERTY_ADDONS,
      PROPERTY_POLICIES,
    ],
    onError: (e) => {
      setShowDeleteConfirmation(false);
      toast(e, { type: 'error' });
      console.log(e);
    },
  });

  const [deletePropertyTypeDetails] = useMutation(DELETE_PROPERTY_TYPE_DETAILS, {
    onCompleted: (data) => {
      setShowDeleteUnitsConfirmation(false);
      if (data.deletePropertyTypeDetails.message) {
        toast(data.deletePropertyTypeDetails.message, { type: 'success' });
      }
      if (data.deletePropertyTypeDetails.errors && data.deletePropertyTypeDetails.errors.length > 0) {
        data.deletePropertyTypeDetails.errors.map((err) => toast(err, { type: 'error' }));
      }
      setIsProgress(false);
      reloadProperties();
    },
    onError: (e) => {
      setShowDeleteUnitsConfirmation(false);
      toast(e, { type: 'error' });
      console.log(e);
    },
  });

  const sanitizePropertiesData = (propertiesData) => {
    let tmpProperties = [];
    let updatedProperty;
    propertiesData &&
      (tmpProperties = propertiesData.properties.edges.map((property) => ({
        ...property.node,
        cityId: property.node.city?.id,
        city: property.node.city?.name,
        neighborhoodId: property.node.neighborhood?.id,
        neighborhood: property.node.neighborhood?.name,
      })));

    propertyChanges?.length > 0 &&
      (tmpProperties = tmpProperties.map((property) => {
        updatedProperty = propertyChanges.find((p) => p.id === property.id);
        return updatedProperty ? updatedProperty : property;
      }));
    return tmpProperties;
  };

  function isAtBottom({ currentTarget }) {
    return (
      currentTarget.scrollTop + 500 >=
      currentTarget.scrollHeight - currentTarget.clientHeight
    );
    // return !pageInfo.hasNextPage;
  }

  async function handleScroll(event) {
    if (propertyLoading || !isAtBottom(event) || pageInfo.hasNextPage === false)
      return;

    setLoadProperties(true);

    // const newRows = await loadMoreRows(50, rows.length);

    // setRows([...rows, ...newRows]);
    // setIsLoading(false);
  }

  useEffect(() => {
    // let tmpProperties = [];
    // propertiesData &&
    //   (tmpProperties = propertiesData.properties.edges.map((property) => ({
    //     ...property.node,
    //     city: property.node.city.name,
    //     neighborhoodId: property.node.neighborhood?.id,
    //   })));
    // console.log(tmpProperties, 'tmpProperties');
    // setProperties(tmpProperties);

    // propertiesData &&
    setProperties(propertiesCopy);

    // propertiesData && setPropertyCount(propertiesData.properties.totalCount);
  }, [resetProperties]); //, propertiesData]);

  useEffect(() => {
    citiesData &&
      setCities(
        [...new Set(citiesData.allCities)].sort(new Intl.Collator().compare)
      );
  }, [citiesData]);

  useEffect(() => {
    neighborhoodsData &&
      setNeighborhoods(
        [...new Set(neighborhoodsData.neighborhoods)].sort(
          new Intl.Collator().compare
        )
      );
  }, [neighborhoodsData]);

  useEffect(() => {
    addonsData &&
      setAddons(
        [...new Set(addonsData.propertyAddons)].sort(
          new Intl.Collator().compare
        )
      );
  }, [addonsData]);

  useEffect(() => {
    policiesData &&
      setPolicies(
        [...new Set(policiesData.propertyPolicies)].sort(
          new Intl.Collator().compare
        )
      );
  }, [policiesData]);

  const savePropertyChanges = (reload = false) => {
    console.log('ANYTHING TO SAVE?');
    if (!isProgress && propertyChanges.length > 0) {
      // Save each property individuall for now.
      // TODO: Create a bulk update mutation, and use that.
      console.log('saving all changes to database');
      // Save
      setIsProgress(true);
      let updatedProperties = propertyChanges.map((row) => {
        delete row.type;
        delete row.__typename;
        delete row.city;
        delete row.neighborhoodId;
        delete row.imageThumbUrls;
        delete row.imageUrls;
        delete row.imageIds;
        delete row.addonItems;
        delete row.policyItems;
        delete row.updatedAt;
        delete row.floorPlans;
        delete row.typeDetails;
        delete row.feesSheetUrl;
        delete row.siteMapUrl;
        delete row.specialsUpdatedAt;
        delete row.unitsUpdatedAt;
        delete row.displayFloorPlanStatus;
        return {
          ...row,
          // __typename: 'Property',
          id: Number(row.id),
          builtYear: Number(row.builtYear) > 0 ? Number(row.builtYear) : null,
          renovated: Number(row.renovated) > 0 ? Number(row.renovated) : null,
          escort: Number(row.escort) > 0 ? Number(row.escort) : null,
          sendEscort:
            Number(row.sendEscort) > 0 ? Number(row.sendEscort) : null,
          lat: Number(row.lat) ? Number(row.lat) : null,
          long: Number(row.long) ? Number(row.long) : null,
          units: Number(row.units) > 0 ? Number(row.units) : null,
          cityId: Number(row.cityId),
          // neighborhoodId: Number(row.neighborhoodId),
        };
        // console.log('====== UPDATING PROPERTY ======');
        // console.log(updatedRow);
        // propertyUpdate({
        //   variables: updatedRow,
        //   optimisticResponse: {
        //     propertyUpdate: {
        //       property: updatedRow,
        //       message: '',
        //       errors: [],
        //     },
        //   },
        // });
      });
      console.log(updatedProperties, '---- updatedProperties');
      propertiesMultiUpdate({
        variables: { updateProperties: updatedProperties },
        // refetchQueries: [PROPERTIES, PROPERTY_ADDONS, PROPERTY_POLICIES],
        // onCompleted: (data) => {
        //   toast(data.propertiesMultiUpdate.message, { type: 'success' });
        //   setIsProgress(false);
        //   // clear the changes
        //   setPropertyChanges([]);
        //   propertyQuery();
        // },
      });
      // setIsProgress(false);
      // // clear the changes
      // setPropertyChanges([]);
      // propertyQuery();

      reloadProperties();
      // setPageInfo({});
      // setLoadProperties(true);
    } else if (reload) {
      // clear the changes
      // setPropertyChanges([]);
      // propertyQuery();
      reloadProperties();
      // setPageInfo({});
      // setLoadProperties(true);
    }
  };

  const skipPropertyChanges = () => {
    const changeCount = propertyChanges.length;
    setResetProperties(!resetProperties);
    setPropertyChanges([]);
    toast(
      `All changes to ${changeCount} ${
        changeCount > 1 ? 'properties' : 'property'
      } were discarded.`,
      { type: 'info' }
    );
  };
  // useInterval(() => {
  //   savePropertyChanges();
  // }, 60000);

  useEffect(() => {
    // console.log(propertyChanges, ' ====> Property Changes');
    let tooltipText = '';
    let classes = '';

    if (isBulkProgress === true || propertyLoading === true) {
      tooltipText = 'Sync in progress';
      classes = 'progress-status';
    } else if (propertyChanges.length > 0) {
      tooltipText = `${propertyChanges.length} unsaved ${
        propertyChanges.length === 1 ? 'property' : 'properties'
      }`;
      classes = 'pending-status';
    } else {
      tooltipText = 'Properties synced';
      classes = 'saved-status';
    }
    if (propertyLoading) {
      classes += ' loading-status';
    }
    setDataGridStatus(
      <>
        <span id='status' className={`grid-status ${classes}`}></span>
        <UncontrolledTooltip placement='right' target='status'>
          {tooltipText}
        </UncontrolledTooltip>
      </>
    );
  }, [isBulkProgress, propertyLoading, propertyChanges]);

  // const summaryRows = useMemo(() => {
  //   const summaryRow = {
  //     id: 'total_0',
  //     totalCount: properties.length,
  //   };
  //   return [summaryRow];
  // }, [properties]);

  const filteredAndSortedProperties = useMemo(() => {
    // Extracting the 'NEW' row
    const newRow = properties.filter((r) => {
      return r.type === 'NEW';
    });

    const filteredAndSortedRows = properties.filter((r) => {
      return (
        // Excluding the 'NEW' type rows by default now,
        // We insert it back in the list after property
        // filtering and sorting is complete.
        r.type !== 'NEW' &&
        (filters.name
          ? r.name?.toLowerCase().includes(filters.name?.toLowerCase())
          : true) &&
        (filters.isConfirmed !== 'All'
          ? filters.isConfirmed === 'No'
            ? r.isConfirmed === false
            : filters.isConfirmed === 'Yes'
            ? r.isConfirmed === true
            : false
          : true) &&
        (filters.autoUpdate !== 'All'
          ? filters.autoUpdate === 'No'
            ? r.autoUpdate === false
            : filters.autoUpdate === 'Yes'
            ? r.autoUpdate === true
            : false
          : true) &&
        (filters.unitsCount !== 'All'
          ? filters.unitsCount === 'No'
            ? r.typeDetails?.length === 0
            : filters.unitsCount === 'Yes'
            ? r.typeDetails?.length > 0
            : false
          : true) &&
        (filters.photos !== 'All'
          ? filters.photos === 'No'
            ? r.imageThumbUrls?.length === 0
            : filters.photos === 'Yes'
            ? r.imageThumbUrls?.length > 0
            : false
          : true) &&
        (filters.city !== 'All' ? r.cityId === filters.city : true) &&
        (filters.neighborhood !== 'All'
          ? r.neighborhoodId === filters.neighborhood
          : true) &&
        (filters.phone
          ? r.phone
              ?.replaceAll(/\s|\(|\)|\-/gi, '')
              .includes(filters.phone?.replaceAll(/\s|\(|\)|\-/gi, ''))
          : true) &&
        (filters.emailFilter !== 'All'
          ? filters.emailFilter === 'No Emails'
            ? r.email?.length === 0 || r.email === null || r.email === ''
            : filters.emailFilter === 'With Emails'
            ? r.email?.length > 0
            : false
          : true) &&
        (filters.googleRating
          ? parseFloat(r.googleRating) >= parseFloat(filters.googleRating)
          : true) &&
        (filters.address
          ? r.address?.toLowerCase().includes(filters.address?.toLowerCase())
          : true) &&
        (filters.zip
          ? r.zip?.toLowerCase().includes(filters.zip?.toLowerCase())
          : true) &&
        (filters.units
          ? r.units?.toString() === filters.units?.toString()
          : true) &&
        (filters.escort
          ? r.escort?.toString() === filters.escort?.toString()
          : true) &&
        (filters.sendEscort
          ? r.sendEscort?.toString() === filters.sendEscort?.toString()
          : true) &&
        (filters.bonus
          ? Number(r.bonus?.replaceAll(/\s|\,|\$/gi, '')) >=
            Number(filters.bonus?.replaceAll(/\s|\,|\$/gi, ''))
          : true) &&
        (filters.builtYear
          ? r.builtYear?.toString() === filters.builtYear?.toString()
          : true) &&
        (filters.renovated
          ? r.renovated?.toString() === filters.renovated?.toString()
          : true) &&
        (filters.specials
          ? r.specials?.toLowerCase().includes(filters.specials?.toLowerCase())
          : true) &&
        (filters.managementCompany
          ? r.managementCompany
              ?.toLowerCase()
              .includes(filters.managementCompany?.toLowerCase())
          : true) &&
        (filters.addons && filters.addons.length > 0
          ? filters.addons.every((searchAddon) =>
              r.addonItems.includes(searchAddon)
            )
          : true) &&
        (filters.policies && filters.policies.length > 0
          ? filters.policies.every((searchPolicy) =>
              r.policyItems.includes(searchPolicy)
            )
          : true) &&
        (filters.specialsAndCommissionPriority !== 'All'
          ? r.specialsAndCommissionPriority?.toLowerCase().includes(filters.specialsAndCommissionPriority?.toLowerCase())
          : true) &&
        (filters.manualPricingFrequency !== 'All'
          ? r.manualPricingFrequency?.toLowerCase().includes(filters.manualPricingFrequency?.toLowerCase())
          : true) &&
        (filters.privateNote
          ? r.privateNote?.toLowerCase().includes(filters.privateNote?.toLowerCase())
          : true) &&
        (filters.communicationMethod !== 'All'
          ? r.communicationMethod?.toLowerCase().includes(filters.communicationMethod?.toLowerCase())
          : true) &&
        (filters.manualUpdate !== 'All'
          ? filters.manualUpdate === 'No'
            ? r.manualUpdate === false
            : filters.manualUpdate === 'Yes'
            ? r.manualUpdate === true
            : false
          : true) &&
        (filters.noCommission !== 'All'
          ? filters.noCommission === 'No'
            ? r.noCommission === false
            : filters.noCommission === 'Yes'
            ? r.noCommission === true
            : false
          : true) &&
        (filters.amenitiesConfirmed !== 'All'
          ? filters.amenitiesConfirmed === 'No'
            ? r.amenitiesConfirmed === false
            : filters.amenitiesConfirmed === 'Yes'
            ? r.amenitiesConfirmed === true
            : false
          : true) &&
        (filters.commissionNote
          ? r.commissionNote?.toLowerCase().includes(filters.commissionNote?.toLowerCase())
          : true) &&
        (filters.policyNote
          ? r.policyNote?.toLowerCase().includes(filters.policyNote?.toLowerCase())
          : true)
      );
    });

    if (sortColumns.length > 0) {
      filteredAndSortedRows.sort((a, b) => {
        for (const sort of sortColumns) {
          const comparator = getComparator(sort.columnKey);
          const compResult = comparator(a, b);
          if (compResult !== 0) {
            return sort.direction === 'ASC' ? compResult : -compResult;
          }
        }
        return 0;
      });
    }

    if (newRow.length === 1) {
      filteredAndSortedRows.splice(0, 0, newRow[0]);
    }
    // setPropertyCount(filteredAndSortedRows.length);

    // console.log(filteredAndSortedRows, '---sortedProperties AFTER');
    console.log(properties, '-------propertiesafter SORT');
    return filteredAndSortedRows;
  }, [properties, signal, sortColumns]);

  useEffect(() => {
    if (addingNew === true) {
      setProperties(
        [
          {
            type: 'NEW',
            id: '-100',
            name: '',
            typeDetails: [],
          },
        ].concat(_.cloneDeep(properties))
      );
    } else {
      // Remove the NEW row
      setProperties(
        _.cloneDeep(properties).filter((r) => {
          return r.type !== 'NEW';
        })
      );
    }
  }, [addingNew]);

  function clearFilters() {
    setFilters(noFilters);
  }

  function toggleFilters() {
    // setFilters((filters) => ({
    //   ...filters,
    //   enabled: !filters.enabled
    // }));
    setFilters((filters) => ({
      ...noFilters,
      enabled: !filters.enabled,
    }));
  }

  const noFilterHeader = (p) => (
    <FilterRenderer {...p}>
      {() => <div className='blank-filters'>&nbsp;</div>}
    </FilterRenderer>
  );

	function applyColorToSpecialsUpdatedAt(date){
		let currentDate = new Date(date)
		let days = (new Date - currentDate)/(24 * 60 * 60 * 1000)
		if (days >= 31) return 'red'
		else if (days >= 15 && days <= 30) return 'orange'
		return 'green'
	}

	function applyColorToPricingLastUpdatedAt(date){
		let currentDate = new Date(date)
		let days = (new Date - currentDate)/(24 * 60 * 60 * 1000)
		if (days >= 15) return 'red'
		else if (days >= 8 && days <= 14) return 'orange'
		return 'green'
	}

	const allColumns = [
		// SelectColumn,
		// id
		{
			key: 'id',
			frozen: true,
			name: 'ID',
			cellClass: 'text-center',
			width: 60,
			minWidth: 60,
		},
		// name
		{
			key: 'name',
			name: 'Name',
			width: 200,
			sortable: true,
			resizable: true,
			frozen: true,
			editor: (p) => <TextEditor {...p} />,
			// formatter: ({ row }) => row.name),
			// summaryFormatter({ row }) {
			//   return <>{row.totalCount} properties</>;
			// },
			headerRenderer: (p) => (
				<FilterRenderer {...p}>
					{({ filters, ...rest }) => (
						<input
							{...rest}
							value={filters.name}
							onChange={(e) => {
								setFilters({
									...filters,
									name: e.target.value,
								});
							}}
							onKeyDown={inputStopPropagation}
						/>
					)}
				</FilterRenderer>
			),
		},
      // map
      {
        key: 'map-button',
        name: '',
        minWidth: isAdmin ? 30 : 40,
        width: 35,
				colSpan(args) {
					return args.type === 'ROW' && args.row.type === 'NEW' ? isAdmin ? 4 : 3 : undefined;
				},
        cellClass: 'text-center p-0',
        formatter({ row }) {
					if (row.type === 'NEW') {
					return (
						<>
							<Button
								className='p-1 mr-1 button-dashboard btn btn-green'
								onClick={() => {
									setIsProgress(true);
									row.id = `123${row.name}`;
									// setAddProperty(row);
									let createdRow = {
										...row,
										isConfirmed: false,
										builtYear:
											Number(row.builtYear) > 0
												? Number(row.builtYear)
												: null,
										renovated:
											Number(row.renovated) > 0
												? Number(row.renovated)
												: null,
										escort:
											Number(row.escort) > 0 ? Number(row.escort) : null,
										sendEscort:
											Number(row.sendEscort) > 0
												? Number(row.sendEscort)
												: null,
										lat: Number(row.lat) > 0 ? Number(row.lat) : null,
										long: Number(row.long) > 0 ? Number(row.long) : null,
										units: Number(row.units) > 0 ? Number(row.units) : null,
										cityId: Number(row.cityId),
										// neighborhoodId: Number(row.neighborhoodId),
									};
									console.log('====== CREATING PROPERTY ======');
									console.log(row);
									propertyCreate({
										variables: createdRow,
										optimisticResponse: {
											propertyCreate: {
												id: Number(row.id),
												__typename: 'Property',
												property: createdRow,
												message: '',
												errors: [],
											},
										},
										onCompleted: (data) => {
											row.type = 'MASTER';
										},
									});
									setAddingNew(false);
									setIsProgress(false);
								}}
							>
								Save
							</Button>
							<Button
								className='p-1 button-dashboard'
								onClick={() => setAddingNew(false)}
							>
								Cancel
								</Button>
							</>
						);
					}
          return row.googleMap !== '' ? (
            <>
              <a
                className='p-2 text-decoration-none'
                href={
                  row.googleMap !== '' ? row.googleMap : 'javascript:void(0)'
                }
                target='_blank'
                rel='noreferrer'
              >
                <Icon icon='map-marker-alt' />
              </a>
            </>
          ) : (
            <></>
          );
        },
      },
      // website
      {
        key: 'website-button',
        name: '',
        minWidth: isAdmin ? 30 : 40,
        width: 35,
        cellClass: 'text-center p-0',
        formatter({ row }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return row.webLink !== '' ? (
            <>
              <a
                className='p-2 text-decoration-none'
                href={row.webLink !== '' ? row.webLink : 'javascript:void(0)'}
                target='_blank'
                rel='noreferrer'
              >
                <Icon icon='globe' />
              </a>
            </>
          ) : (
            <></>
          );
        },
      },
      // email button
      {
        key: 'email-button',
        name: '',
        minWidth: isAdmin ? 30 : 40,
        width: 35,
        cellClass: 'text-center p-0',
        formatter({ row }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return row.email !== '' ? (
            <>
              <a
                className='p-2 text-decoration-none'
                href={`mailto:${row.email}`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon icon='envelope' />
              </a>
            </>
          ) : (
            <></>
          );
        },
      },
			// delete column
			{
				key: 'delete-button',
				name: '',
				minWidth: isAdmin ? 30 : 40,
				width: 35,
				// frozen: true,
				// isLastFrozenColumn: true,
				cellClass: 'text-center p-0',
				formatter({ row }) {
						if (row.type === 'NEW') {
							return <></>;
						}
						return (
							<>
								<div
									key={`del${row.id}`}
									className='w-100 text-center cursor-pointer'
									onClick={() => {
										setDeleteProperties([row]);
										onDeleteClick(row.name);
									}}
								>
									<Icon icon='trash' />
								</div>
							</>
						);
					},
			},
			// city
      {
        key: 'city',
        name: 'Market',
        width: 100,
        sortable: true,
        resizable: true,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                tabIndex={0}
                // autoFocus
                value={filters.city}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    city: e.target.value,
                    neighborhood: 'All',
                  })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllCities'>
                  All
                </option>
                {cities.map((city) => {
                  return (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            )}
          </FilterRenderer>
        ),
        editor: ({ row, onRowChange }) => (
          <Select
            autoFocus
            name='City'
            value={{ value: row.cityId, label: row.city } || 'Select City'}
            options={cities.map((city) => {
              return { value: city.id, label: city.name };
            })}
            onChange={(e) => {
              console.log(e);
              onRowChange({ ...row, cityId: e.value, city: e.label }, true);
            }}
            className='grid-multi-select w-100'
            classNamePrefix='default-select'
            placeholder='Select Market'
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isSearchable
            menuPosition={'absolute'}
            menuPlacement={'auto'}
            menuShouldScrollIntoView={true}
          />
        ),
        formatter: ({ row }) => {
          return row.cityId
            ? cities.filter((city) => city.id === row.cityId)[0]?.name || ''
            : '';
        },
        editorOptions: {
          editOnClick: true,
        },
      },
      // is confirmed checbox
      {
        key: 'isConfirmed',
        name: 'Needs Review',
        minWidth: 120,
        width: 120,
        cellClass: 'text-center p-0',
        formatter({ row, onRowChange }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return (
            <div className='d-flex align-items-center w-100 h-100 px-4'>
              <ToggleButton
                selected={row.isConfirmed || false}
                firstOption={true}
                secondOption={false}
                // width='50px'
                rounded
                toggleSelected={() => {
                  onRowChange({ ...row, isConfirmed: !row.isConfirmed }, true);
                }}
              />
            </div>
          );
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.isConfirmed}
                onChange={(e) =>
                  setFilters({ ...filters, isConfirmed: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllIsConfirmed'>
                  All
                </option>
                <option value='Yes' key='YesIsConfirmed'>
                  Yes
                </option>
                <option value='No' key='NoIsConfirmed'>
                  No
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // Auto update
      {
        key: 'autoUpdate',
        name: 'Auto Update',
        minWidth: 120,
        width: 120,
        cellClass: 'text-center p-0',
        formatter({ row, onRowChange }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return (
            <div className='d-flex align-items-center w-100 h-100 px-4'>
              <ToggleButton
                selected={row.autoUpdate || false}
                firstOption={true}
                secondOption={false}
                // width='50px'
                rounded
                toggleSelected={() => {
                  onRowChange({ ...row, autoUpdate: !row.autoUpdate }, true);
                }}
              />
            </div>
          );
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.autoUpdate}
                onChange={(e) =>
                  setFilters({ ...filters, autoUpdate: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllAutoUpdate'>
                  All
                </option>
                <option value='Yes' key='YesAutoUpdate'>
                  Yes
                </option>
                <option value='No' key='NoAutoUpdate'>
                  No
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // Manual update
      {
        key: 'manualUpdate',
        name: 'Manual Update',
        minWidth: 120,
        width: 120,
        cellClass: 'text-center p-0',
        formatter({ row, onRowChange }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return (
            <div className='d-flex align-items-center w-100 h-100 px-4'>
              <ToggleButton
                selected={row.manualUpdate || false}
                firstOption={true}
                secondOption={false}
                // width='50px'
                rounded
                toggleSelected={() => {
                  onRowChange({ ...row, manualUpdate: !row.manualUpdate }, true);
                }}
              />
            </div>
          );
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.manualUpdate}
                onChange={(e) =>
                  setFilters({ ...filters, manualUpdate: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllManualUpdate'>
                  All
                </option>
                <option value='Yes' key='YesManualUpdate'>
                  Yes
                </option>
                <option value='No' key='NoManualUpdate'>
                  No
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      //Manual Pricing Frequency
      {
        key: 'manualPricingFrequency',
        name: 'Manual Pricing Frequency',
        resizable: true,
        sortable: true,
        width: 220,
        editor: ({ row, onRowChange }) => (
          <select
            name='manualPricingFrequency'
            value={row.manualPricingFrequency || ''}
            onChange={(e) => {
              console.log(e);
              onRowChange({ ...row, manualPricingFrequency: e.target.value }, true);
            }}
          >
            <option value='' key=''>
              
            </option>
            <option value='A(Weekly)' key='A(Weekly)'>
              A(Weekly)
            </option>
            <option value='B(Bi-Weekly)' key='B(Bi-Weekly)'>
              B(Bi-Weekly)
            </option>
            <option value='C(Monthly)' key='C(Monthly)'>
              C(Monthly)
            </option>
            <option value='D(Every 2 Months)' key='D(Every 2 Months)'>
              D(Every 2 Months)
            </option>
            <option value='E(Quarterly)' key='E(Quarterly)'>
              E(Quarterly)
            </option>
            <option value='F(Ignore)' key='F(Ignore)'>
              F(Ignore)
            </option>
          </select>
        ),
        editorOptions: {
          editOnClick: true,
        },
        formatter: ({ row }) => {
          return row.manualPricingFrequency || '';
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                value={filters.manualPricingFrequency}
                onChange={(e) =>
                  setFilters({ ...filters, manualPricingFrequency: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllManualPricingFrequency'>
                  All
                </option>
                <option value='A(Weekly)' key='A(Weekly)'>
                  A(Weekly)
                </option>
                <option value='B(Bi-Weekly)' key='B(Bi-Weekly)'>
                  B(Bi-Weekly)
                </option>
                <option value='C(Monthly)' key='C(Monthly)'>
                  C(Monthly)
                </option>
                <option value='D(Every 2 Months)' key='D(Every 2 Months)'>
                  D(Every 2 Months)
                </option>
                <option value='E(Quarterly)' key='E(Quarterly)'>
                  E(Quarterly)
                </option>
                <option value='F(Ignore)' key='F(Ignore)'>
                  F(Ignore)
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // Display status based on floor plans and it's images
      {
        key: 'displayFloorPlanStatus',
        name: 'Status',
        cellClass: 'text-center',
        width: 60,
        minWidth: 60,
        sortable: true,
      },
      // units
      {
        key: 'typeDetails',
        name: 'Listed Units',
        minWidth: 120,
        width: 120,
        resizable: false,
        cellClass: 'text-center',
        formatter: ({ row }) =>
          row.type !== 'NEW' ? (
            <>
              <div
                className='cursor-pointer'
                onClick={() =>
                  setShowUnitsManager({ show: true, property: row })
                }
              >
                {row.typeDetails?.length > 0 ? (
                  <>
                    {row.typeDetails.length} <sup>units</sup>
                  </>
                ) : (
                  <small>No Units</small>
                )}
              </div>
            </>
          ) : (
            <></>
          ),
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.unitsCount}
                onChange={(e) =>
                  setFilters({ ...filters, unitsCount: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllUnits'>
                  All
                </option>
                <option value='No' key='NoUnits'>
                  No Units
                </option>
                <option value='Yes' key='YesUnits'>
                  With Units
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // delete property units
			{
				key: 'delete-units-button',
				name: 'Delete Units Only',
				minWidth: isAdmin ? 30 : 40,
				width: 125,
				cellClass: 'text-center p-0',
				formatter({ row }) {
						if (row.type === 'NEW') {
							return <></>;
						}
						return (
							<>
								<div
									key={`delUnits${row.id}`}
									className='w-100 text-center cursor-pointer'
                  title='Delete Units Only'
									onClick={() => {
                    setPropertyForDeleteUnits(row);
                    onDeleteUnitsClick();
									}}
								>
									<Icon icon='trash' />
								</div>
							</>
						);
					},
			},
			// Pricing Last Updated
			{
        key: 'unitsUpdatedAt',
        name: 'Pricing Last Updated',
        minWidth: 145,
				resizable: true,
				sortable: true,
        // width: 30,
        cellClass: 'text-center p-0',
				// editor: TextEditor,
        formatter: ({ row }) => (
          <span className='ml-2' style={{ color: applyColorToPricingLastUpdatedAt(row.unitsUpdatedAt)}}>{moment(row.unitsUpdatedAt).fromNow() || '-'}</span>
				),
				
      },
      // communication method
      {
        key: 'communicationMethod',
        name: 'Communication Method',
        minWidth: 120,
        width: 120,
        resizable: false,
        sortable: true,
        cellClass: 'text-center',
        editor: ({ row, onRowChange }) => (
          <select
            name='communicationMethod'
            value={row.communicationMethod || ''}
            onChange={(e) => {
              console.log(e);
              onRowChange({ ...row, communicationMethod: e.target.value }, true);
            }}
          >
            <option value='' key=''>
            </option>
            <option value='email' key='Email'>
              Email
            </option>
            <option value='call' key='Call'>
              Call
            </option>
            <option value='locator_blast' key='LocatorBlast'>
              Locator Blast
            </option>
          </select>
        ),
        editorOptions: {
          editOnClick: true,
        },
        formatter: ({ row }) => {
          return row.communicationMethod || '';
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.communicationMethod}
                onChange={(e) =>
                  setFilters({ ...filters, communicationMethod: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllCommunicationMethods'>
                  All
                </option>
                <option value='email' key='Email'>
                  Email
                </option>
                <option value='call' key='Call'>
                  Call
                </option>
                <option value='locator_blast' key='LocatorBlast'>
                  Locator Blast
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
			// Specials Last Updated
			{
				key: 'specialsUpdatedAt',
				name: 'Specials Last Updated',
				minWidth: 145,
				resizable: true,
        sortable: true,
				// width: 30,
				cellClass: 'text-center p-0',
				formatter: ({ row }) => (
					<span className='ml-2' style={{ color: applyColorToSpecialsUpdatedAt(row.specialsUpdatedAt)}}>{row.specialsUpdatedAt ? moment(row.specialsUpdatedAt).fromNow() : '-'}</span>
				)
			},
      // Specials and Commission Priority
      {
        key: 'specialsAndCommissionPriority',
        name: 'Specials and Commission Priority',
        resizable: true,
        sortable: true,
        width: 220,
        editor: ({ row, onRowChange }) => (
          <select
            name='specialsAndCommissionPriority'
            value={row.specialsAndCommissionPriority || ''}
            onChange={(e) => {
              console.log(e);
              onRowChange({ ...row, specialsAndCommissionPriority: e.target.value }, true);
            }}
          >
            <option value='A(Weekly)' key='A(Weekly)'>
              A(Weekly)
            </option>
            <option value='B(Bi-Weekly)' key='B(Bi-Weekly)'>
              B(Bi-Weekly)
            </option>
            <option value='C(Monthly)' key='C(Monthly)'>
              C(Monthly)
            </option>
            <option value='D(Every 2 Months)' key='D(Every 2 Months)'>
              D(Every 2 Months)
            </option>
            <option value='E(Quarterly)' key='E(Quarterly)'>
              E(Quarterly)
            </option>
            <option value='F(Ignore)' key='F(Ignore)'>
              F(Ignore)
            </option>
          </select>
        ),
        editorOptions: {
          editOnClick: true,
        },
        formatter: ({ row }) => {
          return row.specialsAndCommissionPriority || '';
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                value={filters.specialsAndCommissionPriority}
                onChange={(e) =>
                  setFilters({ ...filters, specialsAndCommissionPriority: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllSpecialsAndCommissionPriority'>
                  All
                </option>
                <option value='A(Weekly)' key='A(Weekly)'>
                  A(Weekly)
                </option>
                <option value='B(Bi-Weekly)' key='B(Bi-Weekly)'>
                  B(Bi-Weekly)
                </option>
                <option value='C(Monthly)' key='C(Monthly)'>
                  C(Monthly)
                </option>
                <option value='D(Every 2 Months)' key='D(Every 2 Months)'>
                  D(Every 2 Months)
                </option>
                <option value='E(Quarterly)' key='E(Quarterly)'>
                  E(Quarterly)
                </option>
                <option value='F(Ignore)' key='F(Ignore)'>
                  F(Ignore)
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
			// Private Note
      {
        key: 'privateNote',
        name: 'Private Note',
        width: 220,
        resizable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.privateNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    privateNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // specials
      {
        key: 'specials',
        name: 'Specials',
        width: 220,
        resizable: true,
        editor: TextEditor,
        // formatter: ({ row }) => <>{row.node.specials}</>,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.specials}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    specials: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // escort
      {
        key: 'escort',
        name: 'Escort',
        cellClass: 'text-center',
        width: 80,
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.escort}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    escort: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
        // editor(p) { console.log(p,"ppp"); return <TextEditor column={{...p.column, key: 'node.escort' }}/> },
        // editor(p) { console.log(p,"ppp"); return <TextEditor onRowChange={{ ...p.row, node: {...p.row.node, sendEscort: ""}}} /> },
        //
        //({column: {key: row.node.escort}}),
        // editor: TextEditor(onRowChange = (p) => { ...p.row, node: {...p.row.node, escort: ""}}),
        // {onRowChange({ ...row, node: {...row.node, escort: ""}})}),
        // formatter: ({ row }) => <>{row.node.escort}</>,
      },
      // send escort
      {
        key: 'sendEscort',
        name: 'Send',
        cellClass: 'text-center',
        width: 80,
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.sendEscort}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    sendEscort: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
        // formatter: ({ row }) => <>{row.node.sendEscort}</>,
      },
      // bonus
      {
        key: 'bonus',
        name: 'Bonus',
        cellClass: 'text-right',
        width: 90,
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.bonus}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    bonus: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
        formatter({ row }) {
          return row.bonus ? (
            <CurrencyFormatter value={Number(row.bonus)} />
          ) : (
            <></>
          );
        },
        // formatter: ({ row }) => <>{row.node.sendEscort}</>,
      },
      // No Commission
      {
        key: 'noCommission',
        name: 'No Commission',
        minWidth: 120,
        width: 120,
        cellClass: 'text-center p-0',
        formatter({ row, onRowChange }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return (
            <div className='d-flex align-items-center w-100 h-100 px-4'>
              <ToggleButton
                selected={row.noCommission || false}
                firstOption={true}
                secondOption={false}
                rounded
                toggleSelected={() => {
                  onRowChange({ ...row, noCommission: !row.noCommission, commissionNote: row.noCommission ? null : "Not paying locators. Last verified " + moment().format('LL'), bonus: row.noCommission ? row.bonus : null, sendEscort: row.noCommission ? row.sendEscort : null, escort: row.noCommission ? row.escort : null }, true);
                }}
              />
            </div>
          );
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.noCommission}
                onChange={(e) =>
                  setFilters({ ...filters, noCommission: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllNoCommission'>
                  All
                </option>
                <option value='Yes' key='YesNoCommission'>
                  Yes
                </option>
                <option value='No' key='NoNoCommission'>
                  No
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // commission note
      {
        key: 'commissionNote',
        name: 'Commission Note',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.commissionNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    commissionNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
			// Scheduling Note
      {
        key: 'schedulingNote',
        name: 'Scheduling Note',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.schedulingNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    schedulingNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
			// Virtual Tours
      {
        key: 'virtualTours',
        name: 'Virtual Tours',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.virtualTours}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    virtualTours: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
			// Parking Note
      {
        key: 'parkingNote',
        name: 'Parking Note',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.parkingNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    parkingNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
			// Invoicing Note
      {
        key: 'invoicingNote',
        name: 'Invoicing Note',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.invoicingNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    invoicingNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
			// Additional Note
      {
        key: 'additionalNote',
        name: 'Additional Note',
				resizable: true,
        cellClass: 'text-left',
        width: 250,
        editor: TextEditor,
				headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.additionalNote}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    additionalNote: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // images
      {
        key: 'imageThumbUrls',
        name: 'Photos',
        minWidth: 120,
        width: 120,
        resizable: true,
        cellClass: 'text-center p-0',
        formatter: ({ row }) =>
          row.type !== 'NEW' ? (
            <>
              <div
                className='cursor-pointer'
                onClick={() =>
                  setShowPhotoEditor({ show: true, property: row })
                }
              >
                {row.imageThumbUrls?.length > 0 ? (
                  <>
                    <img src={row.imageThumbUrls[0]} className='w-100' />
                    {/* Display image count in overlay over image */}
                    <span className='photo-count'>
                      {row.imageThumbUrls.length}
                    </span>
                  </>
                ) : (
                  <small>No Image</small>
                )}
              </div>
            </>
          ) : (
            <></>
          ),
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.photos}
                onChange={(e) =>
                  setFilters({ ...filters, photos: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllPhotos'>
                  All
                </option>
                <option value='No' key='NoPhotos'>
                  No Photos
                </option>
                <option value='Yes' key='YesPhotos'>
                  With Photos
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // site Map
      {
        key: 'site map',
        name: 'Site Map',
        minWidth: 120,
        width: 120,
        resizable: true,
        cellClass: 'text-center p-0',
        formatter: ({ row }) =>
          row.type !== 'NEW' ? (
            <>
              <div
                className='cursor-pointer'
                onClick={() =>
                  setShowSiteMapEditor({show: true, property: row})
                }
              >
								{row.siteMapUrl ? <Icon icon='paperclip' /> : <small>No Site Map</small>}
              </div>
            </>
          ) : (
            <></>
          ),
        headerRenderer: noFilterHeader,
      },
      // fees Sheet
      {
        key: 'fees sheet',
        name: 'Fee Sheet',
        minWidth: 120,
        width: 120,
        resizable: true,
        cellClass: 'text-center p-0',
        formatter: ({ row }) =>
          row.type !== 'NEW' ? (
            <>
              <div
                className='cursor-pointer'
                onClick={() =>
                  setShowFeesSheetEditor({show: true, property: row})
                }
              >
								{row.feesSheetUrl ? <Icon icon='paperclip' /> : <small>No Attachment</small>}
              </div>
            </>
          ) : (
            <></>
          ),
        headerRenderer: noFilterHeader,
      },
      // neighborhood
      {
        key: 'neighborhoodId',
        name: 'Neighborhood',
        width: 150,
        sortable: true,
        resizable: true,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.neighborhood}
                onChange={(e) =>
                  setFilters({ ...filters, neighborhood: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllNeighborhoods'>
                  All
                </option>
                {neighborhoods
                  .filter(
                    (neighborhood) => neighborhood.city.id === filters.city
                  )
                  .map((neighborhood, index) => {
                    return (
                      <option value={neighborhood.id} key={neighborhood.id}>
                        {neighborhood.name}
                      </option>
                    );
                  })}
              </select>
            )}
          </FilterRenderer>
        ),
        // editor: DropDownEditor(options=neighborhoods),
        editor: ({ row, onRowChange }) => (
          <CreatableSelect
            autoFocus
            name='Neighborhood'
            value={
              {
                value: row.neighborhoodId ? row.neighborhoodId : -100,
                label: row.neighborhood,
              } || 'Select Neighborhood'
            }
            options={neighborhoods
              .filter((neighborhood) => neighborhood.city.id === row.cityId)
              .map((neighborhood) => {
                return { value: neighborhood.id, label: neighborhood.name };
              })}
            onChange={(e) => {
              console.log(e);
              onRowChange(
                {
                  ...row,
                  neighborhoodId: e.value && null,
                  neighborhood: e.label,
                },
                true
              );
            }}
            className='grid-multi-select w-100'
            classNamePrefix='default-select'
            placeholder='Select Neighborhood'
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isSearchable
						closeMenuOnSelect={false}
            menuPosition={'absolute'}
            // menuPlacement={'auto'}
            menuShouldScrollIntoView={true}
						style={{ overflowY: 'scroll' }}
          />
        ),
        formatter: ({ row }) => {
          return row.neighborhoodId
            ? neighborhoods.filter(
                (neighborhood) => neighborhood.id === row.neighborhoodId
              )[0]?.name || ''
            : row.neighborhood || '';
        },
        editorOptions: {
          editOnClick: true,
        },
      },
      // phone
      {
        key: 'phone',
        name: 'Phone',
        width: 150,
        resizable: true,
        // sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.phone}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    phone: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // email
      {
        key: 'email',
        name: 'Email',
        minWidth: 230,
        width: 230,
        resizable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                value={filters.emailFilter}
                onChange={(e) =>
                  setFilters({ ...filters, emailFilter: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllEmails'>
                  All
                </option>
                <option value='No Emails' key='NoEmails'>
                  No Emails
                </option>
                <option value='With Emails' key='WithEmails'>
                  With Emails
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // secondary email
      {
        key: 'secondaryEmail',
        name: 'Secondary Email',
        minWidth: 230,
        width: 230,
        resizable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.secondaryEmail}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    secondaryEmail: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // other email
      {
        key: 'otherEmail',
        name: 'Other Email',
        minWidth: 230,
        width: 230,
        resizable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.otherEmail}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    otherEmail: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // website link
      {
        key: 'webLink',
        name: 'Website',
        resizable: true,
        width: 280,
        editor: TextEditor,
        headerRenderer: noFilterHeader,
      },
      // units
      {
        key: 'units',
        name: 'Units',
        width: 80,
        cellClass: 'text-center',
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.units}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    units: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // address
      {
        key: 'address',
        name: 'Address',
        width: 280,
        resizable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.address}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    address: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // zip
      {
        key: 'zip',
        name: 'Zip',
        cellClass: 'text-center',
        width: 80,
        // getRowMetaData: row => row.node.zip,
        sortable: true,
        editor: TextEditor,
        // editor(p) { console.log(p,"ppp"); return <TextEditor {...p} row={p.row.node} column={p.row.node.zip} /> },
        // editor(p) { console.log(p,"ppp"); return <TextEditor column={{...p.column, key: 'node' }}/> },
        // formatter: ({ row }) => <>{row.node.zip}</>,
        // headerRenderer: () => <div className='text-center'>Zip</div>,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.zip}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    zip: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // built year
      {
        key: 'builtYear',
        name: 'Year Built',
        cellClass: 'text-center',
        width: 80,
        sortable: true,
        editor: TextEditor,
        // headerRenderer: () => <div className='text-center'>Year Built</div>,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.builtYear}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    builtYear: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // renovated year
      {
        key: 'renovated',
        name: 'Renovated',
        cellClass: 'text-center',
        width: 80,
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.renovated}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    renovated: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // management company
      {
        key: 'managementCompany',
        name: 'Managed By',
        width: 180,
        resizable: true,
        sortable: true,
        editor: TextEditor,
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                value={filters.managementCompany}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    managementCompany: e.target.value,
                  });
                }}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // Date of management confirmation
      {
        key: 'managementConfirmation',
        name: 'Date of Management Confirmation',
        width: 250,
        resizable: true,
        sortable: true,
        editor: DateEditor,
      },
      // income requirement level
      {
        key: 'incomeRequirementLevel',
        name: 'Income Requirement',
        cellClass: 'text-center',
        width: 180,
        sortable: true,
        editor: TextEditor,
        headerRenderer: noFilterHeader,
      },
      // addon list
      {
        key: 'addonItems',
        name: 'Community Features / Amenities',
        width: 350,
        resizable: true,
        // formatter: ({ row, onRowChange }) => <small>{(row.addonItems?.map((addon) => { return addon }).join(', '))}</small>,
        formatter: ({ row, onRowChange }) =>
          row.addonItems ? (
            row.addonItems.length < 2 ? (
              <>{row.addonItems[0]}</>
            ) : (
              <>
                {row.addonItems[0]} <small>+{row.addonItems.length - 1}</small>
              </>
            )
          ) : (
            <></>
          ),
        editor: ({ row, onRowChange }) => (
          <CreatableSelect
            autoFocus
            name='Addons'
            isMulti
            closeMenuOnSelect={false}
            value={row.addonItems?.map((addon) =>
              JSON.parse(`{"value":"${addon}", "label":"${addon}"}`)
            )}
            // defaultMenuIsOpen
            options={
              typeof addons !== 'undefined'
                ? Object.values(addons).map((addon) =>
                    JSON.parse(
                      `{"value":"${addon.name}", "label":"${addon.name}"}`
                    )
                  )
                : []
            }
            onChange={(e) => {
              console.log(e);
              onRowChange(
                {
                  ...row,
                  addonList: e.map((addon) => addon.label),
                  addonItems: e.map((addon) => addon.label),
                },
                true
              );
            }}
            className='grid-multi-select no-border w-100'
            classNamePrefix='default-select'
            placeholder='Addons'
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isSearchable
            menuPosition={'absolute'}
            menuPlacement={'auto'}
            menuShouldScrollIntoView={true}
          />
        ),
        editorOptions: {
          editOnClick: true,
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <Select
                name='Addons'
                isMulti
                closeMenuOnSelect={false}
                value={filters.addons?.map((addon) =>
                  JSON.parse(`{"value":"${addon}", "label":"${addon}"}`)
                )}
                // defaultMenuIsOpen
                options={
                  typeof addons !== 'undefined'
                    ? Object.values(addons).map((addon) =>
                        JSON.parse(
                          `{"value":"${addon.name}", "label":"${addon.name}"}`
                        )
                      )
                    : []
                }
                onChange={(e) => {
									console.log("onRowChange", e);
                  setFilters({
                    ...filters,
                    addons: e.map((addon) => addon.value),
                  });
                }}
                className='filter-multi-select w-100'
                classNamePrefix='default-select'
                placeholder='Filter by amenities'
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isSearchable
                menuPosition={'absolute'}
                menuPlacement={'auto'}
                menuShouldScrollIntoView={true}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      // Amenities Confirmed (Tony)
      {
        key: 'amenitiesConfirmed',
        name: 'Amenities Confirmed (Tony)',
        minWidth: 120,
        width: 120,
        cellClass: 'text-center p-0',
        formatter({ row, onRowChange }) {
          if (row.type === 'NEW') {
            return <></>;
          }
          return (
            <div className='d-flex align-items-center w-100 h-100 px-4'>
              <ToggleButton
                selected={row.amenitiesConfirmed || false}
                firstOption={true}
                secondOption={false}
                rounded
                toggleSelected={() => {
                  onRowChange({ ...row, amenitiesConfirmed: !row.amenitiesConfirmed }, true);
                }}
              />
            </div>
          );
        },
        headerRenderer: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                // autoFocus
                value={filters.amenitiesConfirmed}
                onChange={(e) =>
                  setFilters({ ...filters, amenitiesConfirmed: e.target.value })
                }
                style={{ height: '32px' }}
                onKeyDown={selectStopPropagation}
              >
                <option value='All' key='AllAmenitiesConfirmed'>
                  All
                </option>
                <option value='Yes' key='YesAmenitiesConfirmed'>
                  Yes
                </option>
                <option value='No' key='NoAmenitiesConfirmed'>
                  No
                </option>
              </select>
            )}
          </FilterRenderer>
        ),
      },
      // policy list
      {
        key: 'policyItems',
        name: 'Community Policies',
        width: 350,
        resizable: true,
        formatter: ({ row, onRowChange }) =>
          row.policyItems ? (
            row.policyItems.length < 2 ? (
              <>{row.policyItems[0]}</>
            ) : (
              <>
                {row.policyItems[0]}{' '}
                <small>+{row.policyItems.length - 1}</small>
              </>
            )
          ) : (
            <></>
          ),

			editor: ({ row, onRowChange }) => (
				<CreatableSelect
					autoFocus
					name='Policies'
					isMulti
					closeMenuOnSelect={false}
					value={row.policyItems?.map((policy) =>
						JSON.parse(`{"value":"${policy}", "label":"${policy}"}`)
					)}
					options={
						policies.map((policy) =>
									JSON.parse(
										`{"value":"${policy.name}", "label":"${policy.name}"}`
									)
								)
					}
					onChange={(e) => {
						console.log(e);
						onRowChange(
							{
								...row,
								policyList: e.map((policy) => policy.label),
								policyItems: e.map((policy) => policy.label),
							},
							true
						);
					}}
					className='grid-multi-select no-border w-100'
					classNamePrefix='default-select'
					placeholder='Policies'
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					menuPortalTarget={document.body}
					isSearchable
					menuPosition={'absolute'}
					menuPlacement={'auto'}
					menuShouldScrollIntoView={false}
				/>
			),
			editorOptions: {
				editOnClick: true,
			},
			headerRenderer: (p) => (
				<FilterRenderer {...p}>
					{({ filters, ...rest }) => (
						<Select
							name='Policies'
							isMulti
							closeMenuOnSelect={false}
							value={filters.policies?.map((policy) =>
								JSON.parse(`{"value":"${policy}", "label":"${policy}"}`)
							)}
							// defaultMenuIsOpen
							options={
								typeof policies !== 'undefined'
									? Object.values(policies).map((policy) =>
											JSON.parse(
												`{"value":"${policy.name}", "label":"${policy.name}"}`
											)
										)
									: []
							}
							onChange={(e) => {
								setFilters({
									...filters,
									policies: e.map((policy) => policy.label),
								});
							}}
							className='filter-multi-select w-100'
							classNamePrefix='default-select'
							placeholder='Filter by policies'
							styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
							menuPortalTarget={document.body}
							isSearchable
							menuPosition={'absolute'}
							menuPlacement={'auto'}
							menuShouldScrollIntoView={true}
							onKeyDown={inputStopPropagation}
						/>
					)}
				</FilterRenderer>
			),
		},
    // policy note
    {
      key: 'policyNote',
      name: 'Policy Note',
      resizable: true,
      cellClass: 'text-left',
      width: 250,
      editor: TextEditor,
      headerRenderer: (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              value={filters.policyNote}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  policyNote: e.target.value,
                });
              }}
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
		// google map link
		{
			key: 'googleMap',
			name: 'Google Map Link',
			resizable: true,
			width: 280,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// google review link
		{
			key: 'googleReviewLink',
			name: 'Google Reviews',
			resizable: true,
			width: 280,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// lat
		{
			key: 'lat',
			name: 'Latitude',
			width: 170,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// long
		{
			key: 'long',
			name: 'Longitude',
			width: 170,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// floor plan
		{
			key: 'floorPlanLink',
			name: 'Floor Plans',
			width: 280,
			resizable: true,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// photo gallery
		{
			key: 'photoGalleryLink',
			name: 'Photo Gallery',
			width: 280,
			resizable: true,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},
		// virtual tour link
		{
			key: 'virtualTourLink',
			name: 'Virtual Tour Link',
			resizable: true,
			width: 280,
			editor: TextEditor,
			headerRenderer: noFilterHeader,
		},

		// {
		//   key: 'minRent',
		//   name: 'Min Rent',
		//   // editor: TextEditor,
		//   formatter: ({ row }) => <>{row.minRent}</>,
		// },
		// {
		//   key: 'maxRent',
		//   name: 'Max Rent',
		//   // editor: TextEditor,
		//   formatter: ({ row }) => <>{row.maxRent}</>,
		// },
		// {
		//   key: 'availFrom',
		//   name: 'Avail From',
		//   // editor: TextEditor,
		//   formatter: ({ row }) => <>{row.availFrom}</>,
		// },
		// {
		//   key: 'availTo',
		//   name: 'Avail To',
		//   // editor: TextEditor,
		//   formatter: ({ row }) => <>{row.availTo}</>,
		// },
	];

  const columns = useMemo(() => {
		if (isAdmin) {
			return allColumns;
		} else {
			return allColumns.filter((col) => col.key != 'delete-button' && col.key != 'delete-units-button')
		}
  }, [cities, neighborhoods]);

  // function RowRenderer(props) {
  //   const property = props.row.node;
  //   // console.log(JSON.stringify(props), "ROWRENDERER");
  //   // console.log(JSON.stringify({...props, row: property}), "AFTER - ROWRENDERER");

  //   return (
  //     // <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: props.rowIdx })}>
  //     // <PropertyRow {...props} />
  //     <PropertyRow {...{ ...props, row: property }} />
  //     // </ContextMenuTrigger>
  //   );
  // }

  // const rowGetter = (rowIdx) => {
  //   let rows = properties; // this.getRows();
  //   let property = Object.assign({}, rows[rowIdx]);
  //   console.log(rowIdx, 'rowIdx');
  //   console.log(rows, 'rows');

  //   property.get = (key) => {
  //     let splitedkey = key.split('.');

  //     if (key.includes('.')) {
  //       return property[splitedkey[0]]
  //         ? property[splitedkey[0]][splitedkey[1]]
  //         : '';
  //     } else {
  //       return property[key];
  //     }
  //   };

  //   return property;
  // };

  function onPropertyRowsChange(rows, { indexes, column }) {
    console.log('ROWS CHANGED!!!!!!!!');
    const row = rows[indexes[0]];
    const copiedProperties = _.cloneDeep(properties);
    const rowPosition = copiedProperties.findIndex(
      (property) => property.id === row.id
    );
    // console.log(properties[rowPosition], '  ----- original row');
    // console.log(row, '  ----- changed row');
    // console.log(rowPosition, '  ----- rowPosition');
    // console.log(
    //   rowPosition,
    //   ' --- position ',
    //   row.name,
    //   ' --- property ',
    // );
    if (row.type !== 'NEW' && !_.isEqual(row, copiedProperties[rowPosition])) {
      const copiedPropertyChanges = _.cloneDeep(propertyChanges);
      const editedPropertyIndex = copiedPropertyChanges.findIndex(
        (property) => property.id === row.id
      );
      // console.log(propertyChanges, ' ---- propertyChanges');
      // console.log(editedPropertyIndex, ' ---- editedPropertyIndex');
      editedPropertyIndex !== -1
        ? copiedPropertyChanges.splice(editedPropertyIndex, 1, row)
        : copiedPropertyChanges.splice(copiedPropertyChanges.length, 0, row);
      setPropertyChanges(copiedPropertyChanges);
    }
    // Update the edited row
    copiedProperties.splice(rowPosition, 1, { ...row });

    setProperties(copiedProperties);
  }

  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteUnitsConfirmation, setShowDeleteUnitsConfirmation] = useState(false);
  const [deleteProperties, setDeleteProperties] = useState([]);
  const [propertyForDeleteUnits, setPropertyForDeleteUnits] = useState(null);
  const onDeleteClick = () => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };
  const onDeleteUnitsClick = () => {
    setShowDeleteUnitsConfirmation(!showDeleteUnitsConfirmation);
  };

  const handleDelete = async () => {
    console.log(deleteProperties, 'Delete Confirmed, call delete API');
    setIsProgress(true);
    await propertyMultiDelete({
      variables: {
        ids: deleteProperties.map((property) => parseInt(property.id)),
      },
    });

    // const rowPosition = properties.findIndex(
    //   (property) => property.id === deleteProperties[0].id
    // );

    // setProperties(_.cloneDeep(properties).filter((r) => r.id !== deleteProperties[0].id));
    // toast(`Deleted ${deleteProperties[0].name}`, { type: 'success' });
    // setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const handleDeleteUnits = async () => {
    setIsProgress(true);
    await deletePropertyTypeDetails({
      variables: {
        propertyId: parseInt(propertyForDeleteUnits.id),
      },
    });
  };

  return (
    <>
      {/* <FullScreen handle={fullscreen}> */}
      {/* {propertyLoading && <Spinner />} */}
      <Prompt
        when={propertyChanges.length > 0 || isBulkProgress || isProgress}
        message='Are you sure you want to leave?'
      />
      {/* Photo Editor */}
      <PropertyPhotoEditor
        showPhotoEditor={showPhotoEditor}
        setShowPhotoEditor={setShowPhotoEditor}
      />
      {/* Units Manager */}
      <PropertyUnitsManager
        key={showUnitsManager.property && showUnitsManager.property.id}
        showUnitsManager={showUnitsManager}
        setShowUnitsManager={setShowUnitsManager}
        reloadProperties={reloadProperties}
      />

      {/* Fees Sheet Editor */}
			<FeesSheetEditor
				showFeesSheetEditor={showFeesSheetEditor}
				setShowFeesSheetEditor={setShowFeesSheetEditor}
				reloadProperties={reloadProperties}
			/>

      {/*Site Map Editor */}
			<SiteMapEditor
				showEditor={showSiteMapEditor}
				setShowEditor={setShowSiteMapEditor}
				reloadProperties={reloadProperties}
			/>

      {/* Delete Confirmation */}
      <Modal isOpen={showDeleteConfirmation} toggle={onDeleteClick}>
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalBody>
          {deleteProperties.length === 1 ? (
            <>
              Are you sure you want to delete{' '}
              <strong>{deleteProperties[0].name}</strong>?
            </>
          ) : (
            <>
              Are you sure you want to delete{' '}
              <strong>{deleteProperties.length} communities</strong>?
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='danger' disabled={isProgress} onClick={handleDelete}>
            Yes
          </Button>
          <Button onClick={onDeleteClick}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Units Confirmation */}
      <Modal isOpen={showDeleteUnitsConfirmation} toggle={onDeleteUnitsClick}>
        <ModalHeader>Delete Units Confirmation</ModalHeader>
        <ModalBody>
          {propertyForDeleteUnits?.typeDetails.length === 0 ? (
            <>
              No units available to delete.
            </>
          ) : (
            <>
              Are you sure you want to delete{' '}
              <strong>{propertyForDeleteUnits?.typeDetails.length} Units</strong>?
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {propertyForDeleteUnits?.typeDetails.length === 0 ? (
            <>
            </>
          ) : (
            <>
              <Button color='danger' disabled={isProgress} onClick={handleDeleteUnits}>
                Yes
              </Button>
            </>
          )}
          <Button onClick={onDeleteUnitsClick}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Discard Changes Confirmation */}
      <Modal
        isOpen={showDiscardConfirmation}
        toggle={() => setShowDiscardConfirmation(!showDiscardConfirmation)}
      >
        <ModalHeader>Discard Changes</ModalHeader>
        <ModalBody>
          Are you sure you want to discard changes to{' '}
          <strong>
            {propertyChanges.length}{' '}
            {propertyChanges.length === 1 ? 'property' : 'properties'}
          </strong>
          ?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={() => {
              skipPropertyChanges();
              setShowDiscardConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setShowDiscardConfirmation(!showDiscardConfirmation)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Row>
        <Col md='6' className='p-2 text-left d-flex align-items-center'>
          Showing {propertyCount} properties
          {dataGridStatus}
          {propertyChanges.length > 0 ? (
            <>
              <Button
                id='save-changes'
                color='blue'
                onClick={() => savePropertyChanges(true)}
                className='ml-3 btn btn-sm'
                disabled={addingNew || propertyLoading}
              >
                Save Changes
              </Button>
              <Button
                id='skip-changes'
                onClick={() => setShowDiscardConfirmation(true)}
                className='ml-2 btn btn-sm btn-danger'
                disabled={addingNew || propertyLoading}
              >
                Cancel
              </Button>
            </>
          ) : (
            ''
          )}
        </Col>
        <Col md='6' className='p-2 d-flex align-items-center'>
          <div className='text-right w-100'>
            {/* <Button
                id='full-screen'
                onClick={fullscreen.active ? fullscreen.exit : fullscreen.enter}
                className='ml-2 btn btn-sm'
                // disabled={addingNew}
              >
                <Icon
                  icon={fullscreen.active ? 'compress-alt' : 'expand-alt'}
                  // className='spinner'
                />
              </Button>
              <UncontrolledTooltip placement='bottom' target='full-screen'>
                {fullscreen.active ? 'Exit ' : 'Enter '} full screen
              </UncontrolledTooltip> */}

            <Button
              id='refetch'
              onClick={() => savePropertyChanges(true)}
              className='ml-2 btn btn-sm'
              disabled={addingNew || propertyLoading}
            >
              <Icon icon='sync-alt' />
            </Button>
            <UncontrolledTooltip placement='bottom' target='refetch'>
              Sync properties
            </UncontrolledTooltip>
            <Button
              onClick={() => setAddingNew(true)}
              color='green'
              className='ml-2 btn btn-sm'
              disabled={addingNew || propertyLoading}
            >
              <Icon icon='plus' className='mr-1' /> Add Property
            </Button>
            <Button
              onClick={toggleFilters}
              color='blue'
              className='btn-sm ml-2'
            >
              <Icon icon='filter' className='mr-1' /> Toggle Filters
            </Button>
            <Button
              onClick={clearFilters}
              // color='blue'
              disabled={
                filters.enabled && !_.isEqual(noFilters, filters) ? false : true
              }
              className='btn-sm ml-2'
            >
              <Icon icon='times' className='mr-1' /> Clear Filters
            </Button>
          </div>
        </Col>
      </Row>
      <Row className='property-grid px-2 pb-2'>
        <Col md='12' className='p-0'>
          <FilterContext.Provider value={filters}>
            <DataGrid
              // ref={grid => this.grid = grid}
              rowKeyGetter={rowKeyGetter}
              // rowGetter={rowGetter}
              columns={columns}
              // rows={rowGetter}
              rows={filteredAndSortedProperties}
              // rowCount={properties.length}
              // rowRenderer={RowRenderer}
              // summaryRows={summaryRows}
              sortColumns={sortColumns}
              onSortColumnsChange={setSortColumns}
              onRowsChange={onPropertyRowsChange}
              onScroll={handleScroll}
              cellNavigationMode='NONE'
              // selectedRows={selectedProperties}
              // onSelectedRowsChange={setSelectedProperties}
              // headerRowHeight={45}
              headerRowHeight={filters.enabled ? 75 : 45}
              // enableVirtualization={false}
              rowHeight={35}
              // defaultColumnOptions={{
              // 	sortable: true,
              // 	resizable: true
              // }}
              // className="fill-grid"
              enableCellAutoFocus={false}
            />
            {propertyLoading && (
              <div className='text-muted'>Loading more properties...</div>
            )}
          </FilterContext.Provider>
        </Col>
      </Row>
      {/* </FullScreen> */}
    </>
  );
}
